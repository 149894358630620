import React, { Component } from "react";
import axios from "../../api.js";
import * as config from "../../config";
import { msalInstance } from "../../authConfig";
import { confirmAlert } from "react-confirm-alert";
import warningIcon from "../MCGL2MappingComponent/Warning.png";
import { toast } from "react-toastify";
import * as messages from "../../messages.js";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as FileSaver from "file-saver";
import * as excel from "exceljs";
import moment from "moment";

toast.configure();

export default class DataAugmentationNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isLoading: props.isLoading,
      isButtonDisabled: false,
    };
  }

  //we define routes to menu items
  openUrl = (event, url) => {
    if (event.ctrlKey) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  // Get logged-in user email address
  getLoggedInUserEmail = () => {
    try {
      var email =
        msalInstance.getAllAccounts()[0] !== null
          ? msalInstance.getAllAccounts()[0].username
          : "";
      return email;
    } catch (error) {
      console.log(error);
    }
  };

  // Method to handle opening the administrative-function dropdown menu
  handleAdministrativeFunctionMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  // Method to handle closing the administrative-function dropdown menu
  handleAdministrativeFunctionMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  // Refresh Head-Count
  refreshHeadCount = async (actionType) => {
    try {
      if (this.state.isButtonDisabled) {
        return;
      }
      var refreshObj = {
        UserName: this.getLoggedInUserEmail(),
        ActionType: actionType,
      };

      this.setState({ isButtonDisabled: true });
      this.props.updateParentState({ isLoading: true });
      let apiResult = await axios
        .get(
          config.baseURL +
            "refreshHeadCount/" +
            encodeURIComponent(JSON.stringify(refreshObj))
        )
        .then((response) => {
          return response;
        });

      var result = apiResult.data[0];
      var message = messages.exportSuccess;
      if (actionType === "View") {
        this.exportHeadcountExcel(result);
      } else {
        message = apiResult.data[0][0].value;
      }
      if (message.toLowerCase().indexOf("success") !== -1) {
        toast.success(message, { autoClose: 10000 });
      } else {
        toast.error(message, { autoClose: 10000 });
      }
      this.setState({ isButtonDisabled: false });
      this.props.updateParentState({ isLoading: false });
    } catch (error) {
      console.error(error);
      this.setState({ isButtonDisabled: false });
      this.props.updateParentState({ isLoading: false });
      toast.error(messages.Error, { autoClose: 10000 });
    } finally {
      this.setState({ isButtonDisabled: false });
      this.props.updateParentState({ isLoading: false });
    }
  };

  //export
  exportHeadcountExcel = (data) => {
    // var fileName = "TBM HeadCount_" + this.formattedDateExcelName(new Date());
    var fileName = "TBM HeadCount_" + moment().format("YYYY-MM-DD");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let workbook = new excel.Workbook();
    let worksheet = workbook.addWorksheet("HeadCount");
    var coltoExport = [
      { header: "Category Type", key: "CategoryType", width: 20 },
      { header: "Category Name1", key: "CategoryName1", width: 20 },
      { header: "Category Name2", key: "CategoryName2", width: 20 },
      { header: "Headcount FTE", key: "HeadcountFTE", width: 15 },
      { header: "Headcount FDE", key: "HeadcountFDE", width: 15 },
      { header: "Headcount Contract", key: "HeadcountContract", width: 20 },
      { header: "Headcount Outsourced", key: "HeadcountOutsourced", width: 20 },
      { header: "Effective Date", key: "EffectiveDate", width: 15 },
      { header: "Active Status", key: "ActiveStatus", width: 15 },
      { header: "Start Date", key: "StartDate", width: 20 },
      { header: "End Date", key: "EndDate", width: 20 },
      { header: "Load Date Time", key: "LoadDateTime", width: 20 },
      { header: "Last Updated", key: "LastUpdated", width: 20 },
    ];

    worksheet.columns = coltoExport;
    console.log("Prepared Columns and add to Excel : " + new Date());
    console.log("Added Data Rows Excel : " + new Date());
    worksheet.addRows(data);
    worksheet.getRow(1).font = {
      name: "Calibri",
      family: 2,
      size: 11,
      bold: true,
    };

    worksheet.getCell("A1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffff00" },
    };
    worksheet.getCell("B1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffff00" },
    };
    worksheet.getCell("C1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffff00" },
    };
    worksheet.getCell("D1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffff00" },
    };
    worksheet.getCell("E1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffff00" },
    };
    worksheet.getCell("F1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffff00" },
    };
    worksheet.getCell("G1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffff00" },
    };
    worksheet.getCell("H1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffff00" },
    };
    worksheet.getCell("I1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffff00" },
    };
    worksheet.getCell("J1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffff00" },
    };
    worksheet.getCell("K1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffff00" },
    };
    worksheet.getCell("L1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffff00" },
    };
    worksheet.getCell("M1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffff00" },
    };

    workbook.xlsx.writeBuffer().then(function (buffer) {
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    });
  };

  confirmationForHeadCountRefresh = () => {
    this.handleAdministrativeFunctionMenuClose();
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="conformDiv">
              <div class="WarningMessageMain">
                <img src={warningIcon} alt="warning" />
                <h4>Warning!</h4>
              </div>
              <p className="conformBody" style={{ textAlign: "center" }}>
                You are about to update Elanco Headcount data in TBM. This is an
                irreversible action which changes future TBM Show-Back
                calculations. Are you Sure?
              </p>
              <div className="ClsSearchPOsButton">
                <button
                  onClick={() => {
                    this.refreshHeadCount("View");
                  }}
                  className="Clsbutton ClsSearch"
                >
                  Export
                </button>
                <button
                  onClick={() => {
                    onClose();
                    this.refreshHeadCount("Update");
                  }}
                  className="Clsbutton ClsSearch"
                >
                  Yes
                </button>
                <button onClick={onClose} className="Clsbutton ClsReset">
                  No
                </button>
              </div>
            </div>
          );
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <>
        <button
          className={"tablinks " + (this.props.active ? "active" : "")}
          onClick={this.handleAdministrativeFunctionMenuOpen}
        >
          Administrative Functions
        </button>
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleAdministrativeFunctionMenuClose}
        >
          <MenuItem
            onClick={this.confirmationForHeadCountRefresh}
            disabled={this.state.isButtonDisabled}
          >
            Annual HeadCount Refresh
          </MenuItem>
          <MenuItem onClick={(e) => this.openUrl(e, "/MasterFilter")}>
            TBM Master Filter
          </MenuItem>
        </Menu>
      </>
    );
  }
}
import React, { Component } from "react";


export default class UploadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  

  render() {
    return (
      <div class="body-support-block">
        <div class="tab">
          <button class="tablinks">Unassigned POs in TBM</button>
          <button
            class="tablinks"
            onClick={(e) => {
              window.location.href = "/SearchPo";
            }}
          >
            Search POs
          </button>
          <button class="tablinks active">Awaiting for Approval</button>
          <button class="tablinks" onClick={(e) => {
                  window.location.href = "/MGCList";
                }}>MGC Code/L2 Mapping</button>
        </div>
        <div id="Unassigned" class="tabcontent tabcontent1">
          <div class="container-fluid">
            <div class="row">
              <div class="cardsContainer">
                
                </div>
              </div>
            </div>
          </div>
        </div>
        );
  }
}

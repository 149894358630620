
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
export const SharedModel = ({closePopup,show, tableData,showLoadinggif}) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <LoadingOverlay active={showLoadinggif} spinner>
    {showLoadinggif === true ? (
      ""
    ) : (
    <Modal show={show} className="detailsModel">
              <Modal.Header style={{ backgroundColor: "#004267" }}>
                <Modal.Title style={{ color: "#fff" }}>Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div class="addColumnsMain">
                
                  <div class="columnsDetails">
                            <ul>
                              {tableData.map((value, index) => {
                                return (
                                  <li>
                                    <span className="valueFont">{value.Columns}</span>
                                    <span className="valueFont2">{value.Values}</span>{" "}
                                  </li>
                                );
                              })}
                              <li>
                                    <span></span>
                                    <span></span>{" "}
                             </li>
                            </ul>
                          </div>
                   
                </div>
              </Modal.Body>
              <Modal.Footer>
                 <Button variant="secondary" onClick={closePopup}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            )}
            </LoadingOverlay>
  );
};

export default SharedModel;
export const validationMesageAttributes = 'Please enter Attribute Name.';
export const validationMesage = 'Please provide data to all mandatory fields(*).';
export const softwaremandatory = 'Please provide Software Name.';
export const amountRequired = 'Please enter amount';
export const L1Required = 'Please select Service Line';
export const L2Required = 'Please select Service';
export const mismatchAmount = "Splintered total amount doesn't match the transaction amount. (Remaining amount is not zero) Please ensure the sum of all splinters matches the transaction's amount.";
export const maxColumns = 'Max 4 Columns can be selected';
export const minColumns = 'Please select atleast one column';
export const fromDateGreater='"From Date" should be equal or prior to "To Date"';
export const closingDateValidation='Closing Date  should be greater than previous Closing Date';
export const toDatelesser='"To Date" should be equal or greater than "From Date"';
export const dateRequired='Please select TBM Change Date From and TBM Change Date To';
export const attributeNameRequired='Please select Attribute Name';
export const l2alreadyExists='Service Name is already assigned';
export const attributeNameExists='Attribute Name already exists';
export const attributevalueRequired='Please enter attribute value';
export const attributeMisMatch='Excel file contains unknown / new columns.Unable to import this data.';
export const allocationCodeMisMatch='Excel file contains unknown / new allocation codes';
export const mgcCodeMisMatch='Excel file contains unknown / new MGC codes.Unable to import this data.';
export const importSuccess='Import data successfull';
export const invalidExcel='Please import a valid File .xlsx';
export const noRowsExcel='Excel file has no rows to import';
export const commentsRequired='Please add comments';
export const commentRequired='Please add comment.';
export const removeAttr='You are about to delete the attribute "Attribute A1". This will affect all MGC codes in TBM. Are you sure you want to delete it? Are you sure you want to delete it?'
export const RefferenceIdalreadyExists='PO/Reference Id already exists';
export const ERRATTR100="Excel file contains new Attribute columns - unable to import";
export const ERRATTR101="Excel file is missing Attributes - unable to import";
export const ERRATTR102="Excel file contains duplicate Attribute columns - unable to import";
export const ERRMGC103="Excel file contains new MGC code(s) - unable to import";
export const ERRMGC104="Excel file contains duplicate MGC code(s) ";
export const ERRMGC105="Excel file has blank MGC Code(s) - unable to import";
export const ERRMGC106="Excel file has blank MGC Description(s) - unable to import";
export const SessionExpired="Session got expired please refresh your screen.";
export const DuplicateL1L2="Duplicate Service Line and Service found.";
export const Error = 'Please try again';
export const NegativeNumberError = 'Please enter negative values.';
export const PositiveNumberError = 'Please enter positive values.';
export const PoRequired = 'Please enter PO Number';
export const PoVendorRequired = 'Please enter PO Number or Vendor Name to Search';
export const NoPoFound = 'No Rule Exists  for PO';
export const NoTransactions = 'No data found for the given search criteria';
export const DocNumberValidation = 'Please enter alphanumeric characters';
export const FiscalValidation = 'Please enter numerical character';
export const numbervalidation = 'Value should be less than or equal to 27 characters';
export const NoZeroValidation = 'Amount and Quantity fields should  not be equal to 0';
export const duplicateEntityId = 'Combination of Document Number and Invoice Document Item already exixts';
export const FiscalYearValidation = 'Please enter Fiscal Year between 2020 to 2050';
export const CurrencyValidation = 'Please enter valid Currency Code';
export const RuleRequired = 'There is no rule to apply.Please search for a rule to assign';
export const TransactionRequired = 'Please select atleast one transaction to assign rule';
export const ReprocessTransactionRequired = 'Please select atleast one transaction to Re-Process';
export const exportSuccess='Export data successfully';
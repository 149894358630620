import axios from '../../api.js';
import React, { Component } from "react";
import * as config from "../../config";
import LoadingOverlay from "react-loading-overlay";
export default class AboutTBM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      versionNumber:"",
      DateCreated:"",
      isLoading:true
    };
    axios
      .get(config.baseURL + "getAboutTBMDetails")
      .then((response) => {
        
        this.setState({ versionNumber: response.data.versionNumber });
        this.setState({ DateCreated: response.data.DateCreated });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }
 render() {
    return (
      <LoadingOverlay active={this.state.isLoading} spinner>
        {this.state.isLoading === true ? (
          ""
        ) : (
            <div class="row alignmargin alignabotText">
            <div class="w-100" style={{margintop:"20px"}}>
                <div class="ml-4 pl-2 pr-2 mr-4">
                    <div class="panel-bg mt-3" style={{marginbottom: "80px"}}>
                        <div class="panelTitle panelTitle2 alignabotText">
                            <div class="col-12">
                                <strong> About TBM UI </strong>
                            </div>
                        </div>
                        <div className="alignabotText" style={{padding: "20px 80px"}}>
                                <p>UI version {this.state.versionNumber} released on {this.state.DateCreated}<br/>
                                    For maintenance and troubleshooting of this solution please contact <a href="https://thespot.elanco.com" target="_blank" rel="noreferrer" style={{color:"#0072ce"}}>https://thespot.elanco.com</a>
                                    </p>
                                    <hr/>
                                </div>
                                <div class="row alignabotTextleft">
                                    <div class="col-6" style={{padding: "20px 100px 80px"}}>
                                        <h4 style={{color:"#0072ce"}}>About the TBM Solution</h4><br/>
                                        <p>Technology Business Management (TBM) is a value management framework for decision-making by Elanco’s executives. It drives the measuring, optimization, and operation of management practices for IT spend across the company.</p><br/>
                                        <p>This Cost Transparency &amp; Allocation solution has a front end and a back end. The front end consists of multiple Power BI dashboards for reporting and analysis, and this UI tool for mapping financial data to the TBM framework. The back end consists of multiple cooperating parts, central to which is a database and a rules engine that learns by example.</p><br/>
                                        <p>This TBM solution blends financial and operational data to achieve output views reflecting the cost of IT services across the company. Such information cannot be extracted from traditional financial general ledgers. </p><br/>
                                    </div>
                                    <div class="col-6" style={{padding: "20px 100px 80px"}}><br/><br/>
                                        <p>The data augmentation and alignment enable the visualization of IT services Cost Transparency. It helps understand Run and Growth investments across Elanco business, while supporting decision making to achieve cost optimization and re-prioritization of spend. The solution also enables benchmarking against external companies leveraging services offered by companies such as Gartner &amp; Deloitte.</p><br/>
                                        <h4 style={{color:"#0072ce"}}>References</h4>
                                        <p><strong>The TBM framework:</strong><a href="https://www.tbmcouncil.org" target="_blank" rel="noreferrer" style={{color:"#0072ce"}}> https://www.tbmcouncil.org/</a> and <a href="https://cio-wiki.org/wiki/Technology_Business_Management_(TBM)" target="_blank" rel="noreferrer" style={{color:"#0072ce"}}>https://cio-wiki.org/wiki/Technology_Business_Management_(TBM)</a></p>
                                        <p><strong>Ingestion logic and rules engine:</strong> Elanco - TBM Allocation - Requirements Specification Document.docx</p>
                                        <p><strong>Implementation details:</strong> Elanco - TBM Allocation - Data Asset Design.docx</p>
                                    </div>
                                </div>
                         </div>
                        </div>
            </div>
            </div>
        )}
      </LoadingOverlay>
    );
  }
}

import React, { Component } from "react";
import DataAugmentationNavBar from "../DataAugmentationNavBarComponent/DataAugmentationNavBar.jsx";

export default class NavBarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //we define routes to menu items
  openUrl = (event, url) => {
    if (event.ctrlKey) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  render() {
    return (
      <div class="tab">
        <button
          className={
            "tablinks " +
            (this.props.activeTab === "UnAssignedPOList" ? "active" : "")
          }
          onClick={(e) => this.openUrl(e, "/UnAssignedPOList")}
        >
          Unassigned Transactions
        </button>
        <button
          className={
            "tablinks " + (this.props.activeTab === "SearchPo" ? "active" : "")
          }
          onClick={(e) => this.openUrl(e, "/SearchPo")}
        >
          Search POs
        </button>
        <button
          className={
            "tablinks " + (this.props.activeTab === "MGCList" ? "active" : "")
          }
          onClick={(e) => this.openUrl(e, "/MGCList")}
        >
          MGC Code/Service Mapping
        </button>

        <button
          className={
            "tablinks " +
            (this.props.activeTab === "UpdateRule" ? "active" : "")
          }
          onClick={(e) => this.openUrl(e, "/UpdateRule")}
        >
          Update Rule
        </button>
        <button
          className={
            "tablinks " +
            (this.props.activeTab === "ClosingDates" ? "active" : "")
          }
          onClick={(e) => this.openUrl(e, "/ClosingDates")}
        >
          Closing Dates
        </button>
        <button
          className={
            "tablinks " +
            (this.props.activeTab === "AdjustmentTransaction" ? "active" : "")
          }
          onClick={(e) => this.openUrl(e, "/AdjustmentTransaction")}
        >
          BPC Adjustment TXN
        </button>
        <button
          className={
            "tablinks " +
            (this.props.activeTab === "AssignRule" ? "active" : "")
          }
          onClick={(e) => this.openUrl(e, "/AssignRule")}
        >
          Assign Rule to TXN
        </button>
        <button
          className={
            "tablinks " +
            (this.props.activeTab === "ReProcessTXN" ? "active" : "")
          }
          onClick={(e) => this.openUrl(e, "/ReProcessTXN")}
        >
          Re-Process Unassigned TXN
        </button>
        <DataAugmentationNavBar
          updateParentState={this.props.updateState}
          active={this.props.activeTab === "MasterFilter"}
        />
      </div>
    );
  }
}

import React from "react";
import NAIcon from "../NotAuthorizedComponent/NotAuthorised.png";
function NotAuthorized() {
    return (
        <div style={{width:'50%',margin:'0 auto',marginTop:'15%', textAlign:'center'}}>
            <div class="card">
                <div class="card-body">
                    <div class="mb-2">
                        <img src={NAIcon} alt="" style={{width: "50px"}} />
                    </div>
       You are not authorized to access TBM site.<br></br>
       Please contact <a href="mailto:IT-VMO@elancoah.com">IT-VMO@elancoah.com</a> for access to the application.
      </div>
            </div>
        </div>)
}
export default NotAuthorized;
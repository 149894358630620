// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}
.fixed-top {
  z-index: 999 !important;
}
.logo {
  width: 90px;
  height: 45px;
}

#basic-nav-dropdown {
  /* display: flex !important; */
  content: none;
  padding: 0.5rem 0.5rem;
}

.dropdown-toggle::after {
  margin-top: 10px;
  display: none !important;
}
.profile-icon {
  background-color: grey;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 1.3;
}

.profile-icon-inner {
  background-color: grey;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 2.3;
  text-align: center;
  vertical-align: middle;
}

.dropdown-item-custom:active,
.dropdown-item-custom:hover {
  background-color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/HeaderComponent/header.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;AACA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE,iDAAiD;AACnD;AACA;EACE,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,8BAA8B;EAC9B,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,wBAAwB;AAC1B;AACA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;;EAEE,sBAAsB;AACxB","sourcesContent":["a.navbar-brand {\n  white-space: normal;\n  text-align: center;\n  word-break: break-all;\n}\n\nhtml {\n  font-size: 14px;\n}\n@media (min-width: 768px) {\n  html {\n    font-size: 16px;\n  }\n}\n\n.box-shadow {\n  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);\n}\n.fixed-top {\n  z-index: 999 !important;\n}\n.logo {\n  width: 90px;\n  height: 45px;\n}\n\n#basic-nav-dropdown {\n  /* display: flex !important; */\n  content: none;\n  padding: 0.5rem 0.5rem;\n}\n\n.dropdown-toggle::after {\n  margin-top: 10px;\n  display: none !important;\n}\n.profile-icon {\n  background-color: grey;\n  border-radius: 50%;\n  width: 35px;\n  height: 35px;\n  text-align: center;\n  line-height: 1.3;\n}\n\n.profile-icon-inner {\n  background-color: grey;\n  border-radius: 50%;\n  margin-right: 10px;\n  display: inline-block;\n  width: 35px;\n  height: 35px;\n  line-height: 2.3;\n  text-align: center;\n  vertical-align: middle;\n}\n\n.dropdown-item-custom:active,\n.dropdown-item-custom:hover {\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

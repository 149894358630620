import React from "react";
import "./App.css";
import "./style.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/HeaderComponent/header";
import Footer from "./components/FooterComponent/footer";
import "bootstrap/dist/css/bootstrap.css";
import NotAuthorized from "./components/NotAuthorizedComponent/notauthorized";
import UnAssignedPOList from "./components/UnassignedPOList/UnassignedList";
import LandingPage from "./components/LandingComponent/landingpage";
import AboutTBM from "./components/LandingComponent/AboutTBM";
import SplitComponent from "./components/SplitComponent/SplitAssign";
import SearchComponent from "./components/SearchComponent/SearchPo";
import AssignL2 from "./components/AssignL2Component/AssignL2";
import Upload from "./components/UploadComponent/upload";
import MCGList from "./components/MCGL2MappingComponent/MCGL2List";
import EditMCG from "./components/MCGL2MappingComponent/EditMCG";
import ApprovalList from "./components/AwaitingforApproval/AwaitingforApprovalListPage";
import Approval from "./components/AwaitingforApproval/AwaitingApproval";
import UpdateRule from "./components/UpdateRule/UpdateRule";
import ClosingDate from "./components/ClosingDates/ClosingDate";
import  PlugTranscation from "./components/PlugTransaction/PlugTranscation";
import AssignRule from "./components/AssignRule/AssignRule";
import Reprocess from "./components/ReProcessTransaction/ReprocessUnassignedTNX";
import TBMMasterFilter from "./components/TBMMasterFilter/TBMMasterFilter";

import axios from "./api.js";
import * as config from "./config";
import { msalInstance } from "./authConfig";
import LoadingOverlay from "react-loading-overlay";
import  "./components/ContactComponent/contactview.css";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAccessable: true,
      isLoading:false   
    };
    
    //this.validateUser();
  }

  validateUser = () => {
    var userId =
      msalInstance.getAllAccounts()[0] !== null
        ? msalInstance.getAllAccounts()[0].username
        : "";
        this.setState({ isLoading: true }); 
        console.log("Method Called fro test")
    axios
      .get(config.baseURL + "getUserGroups/" + userId)
      .then((response) => {
        console.log("Method executed for  test")
        // handle success
        this.setState({ isLoading: false }); 
        this.setState({ isAccessable: response.data });
      })
      .catch((error) => {
        // handle error
        this.setState({ isLoading: false }); 
        console.log(error);
      });
  };

  

  render() {
    return (
      <LoadingOverlay active={this.state.isLoading} spinner>
        {this.state.isLoading === true ? (
          ""
        ) : (
          <div>
            <Header />
            <Router>
            <div>
          <Switch>
            <Route exact path="/" component={this.state.isAccessable?LandingPage:NotAuthorized}></Route>
            <Route
              exact
              path="/UnAssignedPOList"
              component={this.state.isAccessable?UnAssignedPOList:NotAuthorized}
            ></Route>
            <Route
              exact
              path="/SplitorAssign"
              component={this.state.isAccessable?SplitComponent:NotAuthorized}
            ></Route>
            <Route exact path="/SearchPo" component={this.state.isAccessable?SearchComponent:NotAuthorized}></Route>
            <Route exact path="/AssignL2" component={this.state.isAccessable?AssignL2:NotAuthorized}></Route>
            <Route exact path="/Upload" component={this.state.isAccessable?Upload:NotAuthorized}></Route>
            <Route exact path="/MGCList" component={this.state.isAccessable?MCGList:NotAuthorized}></Route>
            <Route exact path="/EditMGC" component={this.state.isAccessable?EditMCG:NotAuthorized}></Route>
            {/* <Route exact path="/ApprovalList" component={this.state.isAccessable?ApprovalList:NotAuthorized}></Route>
            <Route exact path="/Approval" component={this.state.isAccessable?Approval:NotAuthorized}></Route> */}
            <Route exact path="/AboutTBM" component={this.state.isAccessable?AboutTBM:NotAuthorized}></Route>
            <Route exact path="/UpdateRule" component={this.state.isAccessable?UpdateRule:NotAuthorized}></Route>
            <Route exact path="/ClosingDates" component={this.state.isAccessable?ClosingDate:NotAuthorized}></Route>
            <Route exact path="/AdjustmentTransaction" component={this.state.isAccessable?PlugTranscation:NotAuthorized}></Route>
            <Route exact path="/AssignRule" component={this.state.isAccessable?AssignRule:NotAuthorized}></Route>
            <Route exact path="/ReProcessTXN" component={this.state.isAccessable?Reprocess:NotAuthorized}></Route>
            <Route exact path="/MasterFilter" component={this.state.isAccessable?TBMMasterFilter:NotAuthorized}></Route>
           </Switch>
        </div>
            </Router>
            <Footer />
            <div
              className="overlay"
              style={{ display: "none" }}
              id="div-overlay"
            ></div>
          </div>
        )}
      </LoadingOverlay>
    );
  }
}
export default App;
import React, { useState } from "react";
import { Nav, NavDropdown, Button } from "react-bootstrap";
import { msalInstance } from "../../authConfig";
import "../HeaderComponent/header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Icon from '../HeaderComponent/elanco-logo.svg';

function Header() {
  const [isAdmin, setAdmin] = useState(false);
  const location = window.location;
  if (
    location.pathname !== undefined &&
    location.pathname.toLowerCase() === "/admin" &&
    !isAdmin
  ) {
    setAdmin(true);

  }
  console.log("MSAL INSTANCE =======================================================",msalInstance);
  const getName = () => {
    console.log("Get Name ====================",msalInstance.getAllAccounts()[0]);
    const name =
      msalInstance.getAllAccounts()[0] !== null
        ? msalInstance.getAllAccounts()[0].name.split(' ')
        : "";
    const displayName =
      name.length > 1
        ? name[0].substring(0, 1) + name[1].substring(0, 1)
        : name[0].substring(0, 1);
    return displayName;
  };

  return (
    <div style={{ marginBottom: "0px" }}>
      
      <Nav className="navbar fixed-top bg-white" activeKey="/">
        <div>
          <Nav.Item className="color-black">
            <Nav.Link
              className="navbar-brand nav-link-black pt-0 pb-0"
              href="/"
            >
              <span class="ClsLogo">
              <img
                className="logo"
                alt="Elanco"
                src={Icon} 
              />{" "}
              </span>
            </Nav.Link>
            <span class="tagLine"> TBM Cost Transparency & Allocations Tool </span>
          </Nav.Item>
        </div>
        <div>
          <Nav.Item className="ml-auto" style={{ display: "flex" }}>
            {/* <Nav.Link className="navbar-brand nav-link-black" href="/admin">
              {isAdmin}
           {isAdmin ? <img src={settingsActive} alt="" style={{height:'20px',width:'20px'}}></img> : <img src={settings} alt=""></img>}
            </Nav.Link> */}
            <NavDropdown
              title={
                <div className="" style={{ color: "#fff" }}>
                  {msalInstance.getAllAccounts()[0] !== null ? getName() : ""}
                </div>
              }
              id="basic-nav-dropdown"
              className="justify-content-end profile-icon"
            >
              <NavDropdown.Item href="javascsript:void(0)">
                {msalInstance.getAllAccounts()[0] !== null
                  ? msalInstance.getAllAccounts()[0].name.split(' ')
                  : ""}
              </NavDropdown.Item>
              <NavDropdown.Item href="javascsript:void(0)">
                {msalInstance.getAllAccounts()[0] !== null
                  ? msalInstance.getAllAccounts()[0].username
                  : ""}
              </NavDropdown.Item>
              <NavDropdown.Item divider />
              <NavDropdown.Item>
                {msalInstance.getAllAccounts()[0] !== null ? (
                  <Button
                    className="btn btn-primary btn-inner w-100" style={{marginLeft:"0px"}}
                    onClick={() => {
                      msalInstance.logOut();
                      localStorage.clear();
                    }}
                  >
                    Log out
                  </Button>
                ) : (
                  <Button className="btn btn-primary btn-inner w-100">
                    Log out
                  </Button>
                )}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
        </div>
      </Nav>
    </div>
  );
}
export default Header;

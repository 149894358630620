import axios from '../../api.js';
import React, { Component } from "react";
import * as config from "../../config";
import DataTable from "react-data-table-component";
import LoadingOverlay from "react-loading-overlay";
import DataTableExtensions from "react-data-table-component-extensions";
import buttonIcon from "../UnassignedPOList/Split-assign-icon.png";
import ReactTooltip from "react-tooltip";
import DataAugmentationNavBar from "../DataAugmentationNavBarComponent/DataAugmentationNavBar.jsx";

export default class AwaitingforApprovalList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      viewRegistrations: [],
      filterData: [],
      isLoading: true,
      columns: [],
      docDesc:{}
    };
    
  }
  //this method will call when page loads and load data to required controls
  componentDidMount() {
		this._isMounted = true;
    this.getDocDesc();
	  this.getApprovalList();
	}
  //this method will call when page unloads 
  componentWillUnmount() {
		this._isMounted = false;
	}
//this method will fetch DocDescription for doc type
  getDocDesc= async ()=>
  {
    try
    {
      const getDocumentDes=async () => {
      axios
      .get(config.baseURL + "getDocDescriptions")
      .then((response) => {
        var obj = {};
        response.data.forEach(function(e){
        obj[e["DocType"]] = e["DocTypeDescription"]
   })
        this.setState({ docDesc: obj});
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
    }
    await getDocumentDes();
    }
  catch(error)
    {
      console.log(error);
    }
  }
//this method will fetch list of transaction which are in approval Queue  
  getApprovalList= async ()=>
  {
    try
    {
      const getApprovalListData=async () => {
        axios
      .get(config.baseURL + "getApprovalList")
      .then((response) => {
         this.setState({ data: response.data });
        this.setState({ viewRegistrations: response.data });
        this.setState({ filterData: response.data });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
      }
      await getApprovalListData();
    }
    catch(error)
    {

    }
  }
  //Will define columns for datatable
  columns = [
    {
      name: "PO/Reference Id",
      selector: "PurchasingDocument",
      wrap: true,
      sortable: true,

    },
    {
      name: "Vendor Name",
      selector: "VendorName",
      wrap: true,
      sortable: true,
    },
    {
      name: "Cost Element Name",
      selector: "CostElementName",
      wrap: true,
      sortable: true,
    },
    {
      name: "Cost Centre Name",
      selector: "CostCenterName",
      wrap: true,
      sortable: true,
    },
    {
      name: "Document Date",
      selector: "DocumentDate",
      wrap: true,
      sortable: true,

    },
    {
      name: "Document Type",
      selector: "DocumentType",
      sortable: true,
      wrap: true,
      cell: (row) => (

        <span>
          <span
            data-tip={(this.state.docDesc[row.DocumentType] !== null && this.state.docDesc[row.DocumentType] !== undefined && this.state.docDesc[row.DocumentType] !== "") ? this.state.docDesc[row.DocumentType] : "Unknown"}
            data-for="DocType"
          >
            {row.DocumentType}
          </span>

          <ReactTooltip
            aria-haspopup="true"
            role="example"
            className="extraClass"
            id="DocType"
            multiline="true"
            type="dark"
          ></ReactTooltip>
        </span>
      ) 
      
    },
    {
      name: "Performance $'s",
      selector: "Performance",
      sortable: true,
      wrap: true,
      textAlign: "right",
      cell: (row) => row.Performance.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
    },
    {
      name: "Service",
      selector: "ServiceL2",
      sortable: true,
      wrap: true,

    },
    {
      name: "",
      selector: "",
      width: "110px",
      cell: (row) =>
        (
          <button
            className="btn btn-primary"
            onClick={(e) => {
              window.location.href =
                "/Approval?id=" +
                row.EntityId+"&LineId="+row.LineNo
            }}
          >
            <img style={{ width: "33px" }} src={buttonIcon} alt="button"/>
          </button>
        ) 
    },
  ];

  //will create data table and render on page 
  getTableData = () => {
    return (
      <DataTableExtensions
        columns={this.columns}
        data={this.state.data}
        export={false}
        print={false}
      >
        <DataTable
          defaultSortField="id"
          defaultSortAsc={false}
          pagination
          paginationPerPage={20}
          highlightOnHover
          sort
          paginationRowsPerPageOptions={[20, 50, 100, 200]}
          customStyles={this.customStyles}
        />
      </DataTableExtensions>
    );
  };

  //we define routes to menu items
  openUrl = (event, url) => {
    if (event.ctrlKey) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  updateState = (stateObj) => {
    this.setState(stateObj);
  };

  render() {
    return (
      <LoadingOverlay active={this.state.isLoading} spinner>
        {this.state.isLoading === true ? (
          ""
        ) : (
          <div class="body-support-block">
            <div class="tab">
              <button class="tablinks"
              onClick={(e) => this.openUrl(e, "/UnAssignedPOList")}
               >Unassigned Transactions</button>
              <button
                class="tablinks"
                onClick={(e) => this.openUrl(e, "/SearchPo")}
                
              >
                Search POs
              </button>
              <button class="tablinks active">Awaiting for Approval</button>
              <button class="tablinks" 
              onClick={(e) => this.openUrl(e, "/MGCList")}
             >MGC Code/Service Mapping</button>
             <button class="tablinks" onClick={(e) =>
                 this.openUrl(e,"/UpdateRule")
                }>Update Rule</button>
                <button
                class="tablinks"
                onClick={(e) => this.openUrl(e, "/ClosingDates")}
              >
                Closing Dates
              </button>
              <button
                class="tablinks"
                onClick={(e) => this.openUrl(e,"/AdjustmentTransaction")}
              >
                BPC Adjustment TXN
              </button>
              <button
                class="tablinks"
                onClick={(e) => this.openUrl(e, "/AssignRule")}
              >
                Assign Rule to TXN
              </button>
              <button
                class="tablinks"
                onClick={(e) => this.openUrl(e, "/ReProcessTXN")}
              >
               Re-Process Unassigned TXN
              </button>
              <DataAugmentationNavBar updateParentState={this.updateState} />
            </div>
            <div id="Unassigned" class="tabcontent tabcontent1">
              <div class="container-fluid">
                <div class="row">
                  <div class="cardsContainer">
                    <div class="responsive-table tableSearchPlacement approvalamount">
                      {this.getTableData()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </LoadingOverlay>
    );
  }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @media (min-width: 645px) { */
    .confirm-modal-header{
        padding: 8px 16px 4px 16px !important;
    }
    .confirm-modal-title{
        font-size: 18px !important;
        text-align: center !important;
        /* width: 100% !important; */
        font-weight: 600 !important;
        color: red !important;
    }
    .confirm-modal-body{
    width:645px !important;
    overflow:auto !important;
    }
    .copy-button{
        width: 69px;
        height: 39px;
    }
    /* } */`, "",{"version":3,"sources":["webpack://./src/components/SharedModel/errormessage.css"],"names":[],"mappings":"AAAA,gCAAgC;IAC5B;QACI,qCAAqC;IACzC;IACA;QACI,0BAA0B;QAC1B,6BAA6B;QAC7B,4BAA4B;QAC5B,2BAA2B;QAC3B,qBAAqB;IACzB;IACA;IACA,sBAAsB;IACtB,wBAAwB;IACxB;IACA;QACI,WAAW;QACX,YAAY;IAChB;IACA,MAAM","sourcesContent":["/* @media (min-width: 645px) { */\n    .confirm-modal-header{\n        padding: 8px 16px 4px 16px !important;\n    }\n    .confirm-modal-title{\n        font-size: 18px !important;\n        text-align: center !important;\n        /* width: 100% !important; */\n        font-weight: 600 !important;\n        color: red !important;\n    }\n    .confirm-modal-body{\n    width:645px !important;\n    overflow:auto !important;\n    }\n    .copy-button{\n        width: 69px;\n        height: 39px;\n    }\n    /* } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

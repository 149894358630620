import axios from '../../api.js';
import React, { Component } from "react";
import * as config from "../../config";
import DataTable from "react-data-table-component";
import LoadingOverlay from "react-loading-overlay";
import DataTableExtensions from "react-data-table-component-extensions";
import buttonIcon from "../UnassignedPOList/Split-assign-icon.png";
import { msalInstance } from "../../authConfig";
import ReactTooltip from "react-tooltip";
import * as FileSaver from "file-saver";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import excel from "exceljs";
import * as messages from "../../messages.js";
import popupIcon from "../SplitComponent/tick-icon.png";
import Confirmation from "../SharedModel/ErrorMessage";
import NavBarComponent from "../NavBarComponent/NavBarComponent.jsx";

toast.configure();

export default class UnAssignedPOList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      viewRegistrations: [],
      filterData: [],
      isLoading: true,
      columns: [],
      potext: "",
      vendortxt: "",
      strength: "123",
      docDesc: {},
      isDataRetrived: false,
      errorMessage: "",
      exceptionMessageContent: "",
      showConfirmation: false,
    };


  }

  //this method will call when page loads and load data to required controls
  componentDidMount() {
    this._isMounted = true;
    this.getDocDesc();
    this.getUnassignedList(this.getName());

  }
  //this method will call when page unloads 
  componentWillUnmount() {
    this._isMounted = false;
    sessionStorage.removeItem("filtredrow");
    sessionStorage.removeItem("searchInput")
  }

  //get Unassigned list data from db by calling an API
  getUnassignedList = async (username) => {
    try {
      const getMasterData = async (username) => {
        console.log(config.baseURL + "transactionList/" + username)
        axios
          .get(config.baseURL + "transactionList/" + username)
          .then((response) => {
            this.setState({ data: response.data[0] });
            this.setState({ viewRegistrations: response.data[0] });
            this.setState({ filterData: response.data[0] });
            this.setState({ isLoading: false });
            console.log(response.data[0], "response.data[0]")
            this.setState({ filterData: response.data[0] });
            sessionStorage.removeItem("searchInput")
            sessionStorage.setItem("filtredrow", JSON.stringify(response.data[0]));
          })
          .catch((error) => {
            console.log(error);
            this.setState({ isLoading: false });
          });
      }
      await getMasterData(username);
    }
    catch (error) {
      console.log(error);
    }
  }


  //fetch doc descripton based on Doc type
  getDocDesc = async () => {
    try {
      const getDocumentDes = async () => {
        axios
          .get(config.baseURL + "getDocDescriptions")
          .then((response) => {
            this.setState({ isDataRetrived: true });
            var obj = {};
            response.data.forEach(function (e) {
              obj[e["DocType"]] = e["DocTypeDescription"]
            })
            this.setState({ docDesc: obj });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ isLoading: false });
          });
      }
      await getDocumentDes();
    }
    catch (error) {
      console.log(error);
    }
  }
  //Will define columns for datatable
  columns = [
    {
      name: "PO/Reference Id",
      selector: row => row.PurchasingDocument,
      sortable: true,
     wrap: true,
      //width: "13%"
    },
    {
      name: "Vendor Name",
      selector: row => row.VendorName,
      sortable: true,
      wrap: true,
     },
    {
      name: "Cost Element Name",
      selector: row => row.CostElementName,
      sortable: true,
      wrap: true,
   // width: "11%"
    },
    {
      name: "Cost Center Name",
      selector: row => row.CostCenterName,
      sortable: true,
      wrap: true,
      //width: "11%"
    },
    {
      name: "Document Type",
      selector: row => row.DocumentType,
      sortable: true,
      wrap: true,
      //width: "11%",
      cell: (row) => (

        <span>
          <span
            data-tip={(this.state.docDesc[row.DocumentType] !== null && this.state.docDesc[row.DocumentType] !== undefined && this.state.docDesc[row.DocumentType] !== "") ? this.state.docDesc[row.DocumentType] : "Unknown"}
            data-for="DocType"
          >
            {row.DocumentType}
          </span>

          <ReactTooltip
            aria-haspopup="true"
            role="example"
            className="extraClass"
            id="DocType"
            multiline="true"
            type="dark"
          ></ReactTooltip>
        </span>
      )
    },
    {
      name: "Document Date",
      selector: row => row.DocumentDate,
      sortable: true,
      wrap: true,
     //width: "12%"
    },
    {
      name: "Posting Date",
      selector: row => row.PostingDate,
      sortable: true,
      wrap: true,
      //width: "12%"
    },
    {
      name: "Performance $'s",
      selector: row => row.Performance,
      sortable: true,
      wrap: true,
    // width: "12%",
      cell: (row) => row.Performance.toLocaleString(navigator.language, { minimumFractionDigits: 2 }),

    },
    {
      name: "",
      selector: "",
     // wrap: true,
     width: "110px",
      cell: (row) =>
        row.PurchasingDocument !== "" && row.PurchasingDocument !== null ? (
          <button
            className="btn btn-primary"
            onClick={(e) => {
              window.location.href =
                "/SplitorAssign?id=" +
                row.EntityId +
                "&LineNumber=" +
                row.LineNo +
                "&PostingDate=" +
                row.PostingDate;
            }}
          >
            <img style={{ width: "33px" }} src={buttonIcon} alt="icon" />
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={(e) => {
              window.location.href =
                "/AssignL2?id=" + row.EntityId + "&isFrom=unAssigned";
            }}
          >
            <img style={{ width: "33px" }} src={buttonIcon} alt="edit" />
          </button>
        ),
    },
  ];

  customStyles = {
    rows: {
      style: {
        minHeight: '62px',
        fontSize: '14px',
        wordBreak: 'break-all'
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: "rgba(0,0,0,.12)"
        },
      },
    },
  };

  //custom sort
  customSort(rows, selector, direction) {
    // let rowData=(this.state.filterData)
    let sorted = rows.sort((rowA, rowB) => {
      // use the selector function to resolve your field names by passing the sort comparitors
      const aField = selector(rowA);
      const bField = selector(rowB);
      let comparison = 0;
       if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
      else {
        comparison = 0;
      }
      return direction === 'desc' ? comparison * -1 : comparison;
    });
    sessionStorage.setItem("filtredrow", JSON.stringify(sorted));
    return sorted;
  }
  //will create data table and render on page 
  getTableData = () => {
    return (
      <DataTableExtensions
        columns={this.columns}
        data={this.state.filterData}
        export={false}
        print={false}
        filter={false}
      >
        <DataTable
          pagination
          paginationPerPage={20}
          highlightOnHover
          //sort
          sortFunction={this.customSort}
          paginationRowsPerPageOptions={[20, 50, 100, 200]}
          customStyles={this.customStyles}

        />
      </DataTableExtensions>
    );
  };

  //custom search
  globalSearch = (value) => {
    sessionStorage.setItem("searchInput", value);
    let searchInput = value;
    //let searchText=null
   
    const searchText = searchInput.trim().toLowerCase();
    this.setState({ searchText: searchText });
    this.state.data.filter((program) => program.VendorName !== null);
    let searchData = this.state.data.filter(
      (value) =>
        (!!value.PurchasingDocument && value.PurchasingDocument.toLowerCase().includes(searchText)) ||
        ( !!value.VendorName &&
          value.VendorName.toLowerCase().includes(searchText)) ||
        (!!value.CostElementName &&
          value.CostElementName.toLowerCase().includes(searchText)) ||
        (!!value.CostCenterName &&
          value.CostCenterName.toLowerCase().includes(searchText)) ||
        (!!value.DocumentType &&
          value.DocumentType.toLowerCase().includes(searchText)) ||
        (!!value.DocumentDate && value.DocumentDate.toString().toLowerCase().includes(searchText)) ||
        (!!value.PostingDate && value.PostingDate.toLowerCase().includes(searchText))
        ||
        (!!value.PerformanceFormat && value.PerformanceFormat.toLowerCase().includes(searchText))
    );

    this.setState({ filterData: searchData });
    sessionStorage.setItem("filtredrow", JSON.stringify(searchData));
  };

  // this will get name for the loggedin user
  getName = () => {
    try {
      var email =
        msalInstance.getAllAccounts()[0] !== null
          ? msalInstance.getAllAccounts()[0].username
          : "";
      return email;
    } catch (error) {
      console.log(error);
    }
  };

  //fetch data from db by calling an api and export the sheet
  exportDataToExcel = () => {
    var txtvalue = document.getElementsByName("filterDataTable")[0].value;
    var searchValue = (txtvalue !== '' && txtvalue !== null && txtvalue !== undefined) ? txtvalue.trimEnd().trimStart() : "null";
    searchValue = searchValue.replaceAll("/", "%2F");
    searchValue = encodeURIComponent(searchValue);
    window.location.href = config.baseURL + 'downloadUnAssignedPolistExcel/' + searchValue;
  }


  //fetch doc descripton based on Doc type
  getDatatoExport = async () => {
    try {
      this.setState({ isLoading: true });
      const getDocumentDes = async () => {
        var txtvalue = document.getElementsByName("filterDataTable")[0].value;
        var searchValue = (txtvalue !== '' && txtvalue !== null && txtvalue !== undefined) ? txtvalue.trimEnd().trimStart() : "null";
        searchValue = searchValue.replaceAll("/", "%2F");
        searchValue = encodeURIComponent(searchValue);
        axios
          .get(config.baseURL + "downloadUnAssignedPolistExcelNew/" + searchValue)
          .then((response) => {
            console.log(response.data, "excel")
            this.exportUsingExceljs(response.data);
            console.log(response.data, "response.dataecsell")
            this.setState({ isLoading: false });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ isLoading: false });
          });
      }
      await getDocumentDes();
    }
    catch (error) {
      console.log(error);
    }
  }


  exportUsingExceljs = async (data) => {
    var fileName = "TBM Unassigned_Transactions_" + this.formattedDateExcelName(new Date());

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let workbook = new excel.Workbook();
    let worksheet = workbook.addWorksheet("Unassigned Transactions");
    var coltoExport = [
      { header: "Purchasing Document", key: "PurchasingDocument", width: 10 },
      { header: "Plant", key: "plantcode", width: 50 },
      { header: "Cost Center", key: "CostCenter", width: 10 },
      { header: "Cost Center Name", key: "CostCenterName", width: 10 },
      { header: "Cost Element", key: "CostElement", width: 10 },
      { header: "Cost Element Name", key: "CostElementName", width: 10 },
      { header: "Cost Element Desc", key: "CostElementDesc", width: 10 },
      { header: "Posting Date", key: "PostingDate", width: 10 },
      { header: "Line NO", key: "LineNo", width: 10 },
      { header: "Created On", key: "CreatedOn", width: 10 },
      { header: "Document Date", key: "DocumentDate", width: 10 },
      { header: "Document Header Text", key: "DocumentHeaderText", width: 10 },
      { header: "Document Type", key: "DocumentType", width: 10 },
      { header: "Document Type Description", key: "DocTypeDescription", width: 10 },
      { header: "Effective Value", key: "EffectiveValue", width: 10 },
      { header: "Fiscal Year", key: "FiscalYear", width: 10, style: { numFmt: '' } },
      { header: "Invoice Document Item", key: "InvoiceDocumentItem", width: 10 },
      { header: "Performance $'s", key: "Performance", width: 10 },
      { header: "Profit Center", key: "ProfitCenter", width: 10 },
      { header: "Short Text", key: "ShortText", width: 10 },
      { header: "Order Quantity", key: "OrderQuantity", width: 10 },
      { header: "Order Unit", key: "OrderUnit", width: 10 },
      { header: "Net Price", key: "NetPrice", width: 10 },
      { header: "Currency", key: "Currency", width: 10 },
      { header: "Still To Be Delivered Qty", key: "StillToBeDeliveredQty", width: 10 },
      { header: "Still To Be Delivered Val", key: "StillToBeDeliveredVal", width: 10 },
      { header: "Gross Order Value", key: "GrossOrderValue", width: 10 },
      { header: "Net Order Value", key: "NetOrderValue", width: 10 },
      { header: "Object Currency", key: "ObjectCurrency", width: 10 },

      {
        header: "Value in Controlling Area Currency",
        key: "ValCoAreaCrcy",
        width: 10
      },

      {
        header: "Value in Object Currency",
        key: "ValObjCrcy",
        width: 10
      },
      { header: "Period", key: "Period", width: 10 },
      { header: "Price Date", key: "PriceDate", width: 10 },
      { header: "Material Code", key: "MaterialCode", width: 10 },
      { header: "Material Group Code", key: "MaterialGroupCode", width: 10 },
      { header: "Parent MGC (TBM)", key: "TBMMGCCode", width: 10 },
      { header: "Modified By", key: "ModifiedBy", width: 10 },
      { header: "Modified Date", key: "ModifiedDate", width: 10 },
      { header: "IT Function", key: "ITFunction", width: 10 },
      { header: "Rollup Scorecard", key: "RollupScorecard", width: 10 },
      { header: "Cost Element Sub Category", key: "SubCategory", width: 10 },
      { header: "Purchase Order Text", key: "PurchaseOrderText", width: 10 },
      { header: "Acc Asgt Quantity", key: "AccAsgtQuantity", width: 10 },
      { header: "CO Area Currency", key: "COAreaCurrency", width: 10 },
      { header: "Controlling Area", key: "ControllingArea", width: 10 },
      { header: "Company Code", key: "CompanyCode", width: 10 },
      { header: "Requisitioner", key: "Requisitioner", width: 10 },
      { header: "Reference Document Number", key: "ReferenceDocumentNumber", width: 10 },
      { header: "Purchasing Group", key: "PurchasingGroup", width: 10 },
      { header: "Name", key: "Name", width: 10 },
      { header: "Material Type Code", key: "MaterialTypeCode", width: 10 },
      { header: "Supplier Material Number", key: "SupplierMaterialNumber", width: 10 },
      { header: "Document Number", key: "DocumentNumber", width: 10 },
      { header: "PO Number Extraction Reason", key: "PONumberExtractionReason", width: 10 },
      { header: "Entity Id", key: "EntityId", width: 10 },
      { header: "Vendor Name", key: "VendorName", width: 10 },
      { header: "Vendor Code", key: "Vendor", width: 10 },
      { header: "Service Line", key: "ServiceLineL1", width: 10 },
      { header: "Service", key: "ServiceL2", width: 10 },
      { header: "Purchasing Document (PO ID)", key: "POID", width: 10 },
      { header: "Comments", key: "comments", width: 10 },

    ];

    worksheet.columns = coltoExport;
    console.log("Prepared Columns and add to Excel : " + new Date());
    console.log("Added Data Rows Excel : " + new Date());
    worksheet.addRows(data);
    worksheet.getRow(1).font = { name: 'Calibri', family: 2, size: 11, bold: true };

    worksheet.getCell("A1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("B1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("C1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("D1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("E1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("F1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("G1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("H1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("I1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("J1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("K1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("L1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("M1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("N1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("O1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("P1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("Q1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("R1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("S1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("T1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("U1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("V1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("W1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("X1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("Y1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("Z1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AA1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AB1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AC1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AD1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AE1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AF1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AG1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AH1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AI1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AJ1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AK1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AL1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AM1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AN1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AO1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AP1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AQ1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AR1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AS1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AT1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AU1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AV1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AW1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AX1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AY1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("AZ1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("BA1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("BB1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("BC1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
    worksheet.getCell("BD1").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffff00" } };
   
    worksheet.getColumn(19).numFmt = '#,##0.00';
    worksheet.getColumn(16).numFmt = '#,##0.00';
    worksheet.getColumn(24).numFmt = '#,##0.00';
    worksheet.getColumn(27).numFmt = '#,##0.00';
    worksheet.getColumn(28).numFmt = '#,##0.00';
    worksheet.getColumn(29).numFmt = '#,##0.00';
    worksheet.getColumn(31).numFmt = '#,##0.00';
    worksheet.getColumn(32).numFmt = '#,##0.00';
    worksheet.getColumn(22).numFmt = '#,##0.00';
    worksheet.getColumn(26).numFmt = '#,##0.00';
    worksheet.getColumn(43).numFmt = '#,##0.00';
    worksheet.getColumn(56).numFmt='@';
    workbook.xlsx.writeBuffer().then(function (buffer) {
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    });

  }

  //to set excelexport names based on date
  formattedDateExcelName = (d) => {

    if (d != "" && d != undefined) {
      let month = String(d.getMonth() + 1);
      let day = String(d.getDate());
      const year = String(d.getFullYear());

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return `${year}-${month}-${day}`;
    } else {
      return "";
    }
  };

  // import functionality
  //it will clear the file which save in session attribute
  resetSelectedFiles(e) {
    e.target.value = null;
  }
  //method will convert file to base 64 string
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  closeSubmittedPopup() {
    try {
      document.getElementById("submittedPopup").style.display = "none";
      document.getElementById("div-overlay").style.display = "none";
      this.setState({ isLoading: true });

      if (this.getUnassignedList(this.getName())) {
        this.setState({ isLoading: true });
      }


    } catch (error) {
      console.log(error);
    }
  }

  //this method validate the form based on business functionalities
  validateExtension(inputFile) {
    const validFileExtensions = ["xls", "xlsx"];
    if (
      validFileExtensions.includes(
        inputFile.name.split(".").pop().toLowerCase()
      )
    ) {

      return true;
    }
    else {
      return false;
    }
  }
  //this method will import excel file from UI and update the data to db bycalling API
  uploadAttachment(e, el) {

    const files = e.target.files;
    var obj = {};
    for (let i = 0; i < files.length; i++) {
      let file = e.target.files[i];
      let fileName = file.name;
      let fileType = file.type;
      if (this.validateExtension(file)) {
        this.getBase64(file).then((data) => {
          obj = {
            attachmentid: 0,
            documentname: fileName,
            documenttype: "UnAssignedAttributeList",
            documenturl: "",
            documentformat: fileType,
            fileContent: data.split(";base64,")[1],
            isdeleted: false,
            Username: this.getName()
          };
          console.log(obj, "bbbbbjjj")
          this.setState({ selectedFiles: obj });
          this.setState({ isLoading: true });
          axios
            .post(config.baseURL + "UnassignedexcelImport", obj)
            .then((response) => {
              // handle success
              var res = response.data;
              this.setState({ isLoading: false });
              if(res.length>0) {
                var msgTxt = "";
                const myArr = res[0].ErrorMessage.split(";");

                switch (myArr[0]) {
                  case "ERRATTR100":
                    msgTxt = messages.ERRATTR100;
                    break;
                  case "ERRATTR101":
                    msgTxt = messages.ERRATTR101;
                    break;
                  case "ERRATTR102":
                    msgTxt = messages.ERRATTR102;
                    break;
                  case "ERRMGC103":
                    msgTxt = messages.ERRMGC103;
                    break;
                  case "ERRMGC104":
                    msgTxt = messages.ERRMGC104 + myArr[1] + " - unable to import";
                    break;
                  case "ERRMGC105":
                    msgTxt = messages.ERRMGC105;
                    break;
                  case "ERRMGC106":
                    msgTxt = messages.ERRMGC106;
                    break;
                  case "Validations Passed":
                    document.getElementById("submittedPopup").style.display = "block";
                    document.getElementById("div-overlay").style.display = "block";
                    break;
                  default:
                    msgTxt = res[0].ErrorMessage;
                    break;

                }
                if(res[0].ErrorMessage !=="Validations Passed"){
                  var errorMsg = res[0].ErrorMessage.trim().replace(/^\|/, '').trim();
                  this.setState({errorMessage:errorMsg});
                  this.setState({exceptionMessageContent:res[0].ExceptionMessageContent});
                  this.setState({showConfirmation:true}) 
                }
              }
            })
            .catch((error) => {
              // handle error

              this.setState({ isLoading: false });
            });
        });
      }
      else {
        toast.error(messages.invalidExcel, {
          autoClose: false,
          tapToDismiss: false,
    onclick: false,
    closeOnHover: false,
          //pauseOnFocusLoss: false,
         // autoClose: 10000,
        });
      }

    }

  }

  updateState = (stateObj) => {
    this.setState(stateObj);
  };
  render() {
    return (
      <LoadingOverlay active={this.state.isLoading} spinner>
        {this.state.isLoading === true ? (
          ""
        ) : (
          <div class="body-support-block">
            <NavBarComponent updateState={this.updateState} activeTab = 'UnAssignedPOList'/>           
            <div id="Unassigned" class="tabcontent tabcontent1">
              <div class="container-fluid">
                <div class="row">
                  <div class="cardsContainer">

                    <div class="responsive-table tableSearchPlacement amountColumnRightAlign MCGL2Mapping">
                      <div className='searchInputMGC'>
                        <input type="text" name="filterDataTable" className="filter-text"
                          id="txtSearchInput"
                          placeholder='Filter Table'
                          value={sessionStorage.getItem("searchInput")}
                          onInput={(e) => this.globalSearch(e.target.value)}
                        />
                      </div>
                      <div class="exportBtnGroup">
                        <span className="input-group-btn left0">
                          <button
                            className="btn btn-primary btn-file btn-inner"
                            id="importbtn"
                          >
                            Import  Excel{" "}
                            <input
                              type="file"
                              name="importFiles"
                              id="importAttrinput"
                              data-test="importAttrinput1"
                              title=" "
                              onClick={(e) => this.resetSelectedFiles(e)}
                              onChange={(e) => this.uploadAttachment(e, this)}
                              accept=".xlsx"
                            />
                          </button>
                        </span>
                        <button class="btn btn-primary" onClick={
                          (e) => { this.getDatatoExport() }
                        }>Export Excel</button>
                      </div>
                      {this.getTableData()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal show" id="submittedPopup">
              <div class="modal-dialog modal-success" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <div class="successMessageMain">
                      <img src={popupIcon} alt="popup" />
                      <h4>Successfully Imported!</h4>
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={() => this.closeSubmittedPopup()}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.showConfirmation ?(
              <div className="modalhow" id="errorPopup">
                <Confirmation
                  showConfirmation={this.state.showConfirmation}
                  ModalContent={this.state.exceptionMessageContent}
                  handleClose={() => {
                      this.setState({showConfirmation:false})
                  }}
                  handleConfirm={() => {
                    navigator.clipboard.writeText(this.state.errorMessage)
                  }}                  
                  primaryButtonText="Copy"
                  secondaryButtonText="Cancel"
                />
              </div>
            ):""}
          </div>
        )}
      </LoadingOverlay>
    );
  }
}
import React, { Component } from "react";
import * as config from "../../config";
import * as messages from "../../messages.js";
import axios from "../../api.js";
import popupIcon from "../SplitComponent/tick-icon.png";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { msalInstance } from "../../authConfig";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Select from "react-select";
import SharedModel from '../SharedModel/Model';
import warningIcon from "../MCGL2MappingComponent/Warning.png";
import NavBarComponent from "../NavBarComponent/NavBarComponent.jsx";

toast.configure();
const TransactionTypes = [
  {
    label: "Compensation & Benefits",
    value: "cb",
  },
  {
    label: "DEPAM",
    value: "depam",
  },
  {
    label: "Other",
    value: "other",
  },
];
export default class ReprocessUnassignedTNX extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isItFunctionLoading: false,
      filterData: [],
      TransTypes: TransactionTypes,
      TransType: "",
      ITFunctions: [],
      ITFunction: "",
      ProcessTypes: [],
      ProcessType: "",
      showTable:false,
      show:false,
      transctionData:[],
      showLoadinggif:false,
      selectedRows:[]
    };
  }
  //this method will call when page loads and load data to required controls
  componentDidMount = () => {
    this.getName();
    let PTArray = TransactionTypes.filter(function (item) {
      return item.value != "other";
    });
    this.setState({ ProcessTypes: PTArray });
  };

  // this will get name for the loggedin user
  getName = () => {
    try {
      var email =
        msalInstance.getAllAccounts()[0] !== null
          ? msalInstance.getAllAccounts()[0].username
          : "";
      this.setState({ username: email });
      return email;
    } catch (error) {
      console.log(error);
    }
  };

 

  closePopup = () => {
    try {
      this.setState({show:false})
    }
    catch(error)
    {
      console.log(error)
    }
  }

  

  //this method handles functionlity when you close the submit popup
  closeSubmittedPopup() {
    try {
      document.getElementById("submittedPopup").style.display = "none";
      document.getElementById("div-overlay").style.display = "none";
      window.location.href = "/ReProcessTXN";
    } catch (error) {
      console.log(error);
    }
  }

  //this method will fetch list of Closing dates from db by calling API
  getUnassignedTransaction = async () => {
    try {
      if (this.validate()) {
        this.setState({ isLoading: true });
        const fetchData = async () => {
          var inputObj = {
            processType: this.state.TransType,
            Itfunction: this.state.ITFunction,
          };
          axios
            .post(config.baseURL + "getReprocessTransactions", inputObj)
            .then((response) => {
              this.setState({ filterData: response.data[0] });
              this.setState({ isLoading: false,showTable:true });
            })
            .catch((error) => {
              console.log(error);
              this.setState({ isLoading: false });
            });
        };
        await fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  //this method will fetch list of It function based on transaction type
  getITFunctions = async (transType) => {
    try {
      this.setState({ isItFunctionLoading: true });
      const fetchItFunctions = async () => {
        axios
          .get(config.baseURL + "getItFunctions/" + transType)
          .then((response) => {
            this.setState({
              ITFunctions: response.data[0],
              isItFunctionLoading: false,
            });
            this.state.ITFunctions.unshift({ label: "Select All", value:"Select All" });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ isItFunctionLoading: false });
          });
      };
      await fetchItFunctions();
    } catch (error) {
      console.log(error);
    }
  };

  validate = () => {
    try {
      if (this.state.TransType == "" ) {
        toast.error(messages.validationMesage, {
          autoClose: 10000,
        });
        return false;
      } 
      else if (
        (this.state.TransType == "depam" || this.state.TransType == "cb") &&
        this.state.ITFunction == ""
      ) {
        toast.error(messages.validationMesage, {
          autoClose: 10000,
        });
        return false;
      } else if (
        this.state.TransType == "other" &&
        this.state.ProcessType == ""
      ) {
        toast.error(messages.validationMesage, {
          autoClose: 10000,
        });
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  updateState = (state) => {
    this.setState({ selectedRows: state.selectedRows }); // triggers MyComponent to re-render with new state
  };


  bindPoPUpData = (transId, linenumber) => {
    this.setState({showLoadinggif:true,transctionData:[]});
    var obj = {
      entityId: transId,
      linenumber: linenumber,
      UserName: this.getName(),
    };
     axios
      .post(config.baseURL + "getPODetails", obj)
      .then((response) => {
        this.setState({ transctionData: response.data[0] });
        this.setState({ showLoadinggif: false,show:true});
        
      })
      .catch((error) => {
        console.log(error);
        this.setState({ showLoadinggif: false });
      });
  };

  //Will define columns for datatable for TransType Depam,CB
  columns = [
    {
      name: "PO/Reference Id",
      selector: "Purchasing Document",
      sortable: true,
      wrap:true,
      width:"150px"
    },
    {
      name: "Vendor Name",
      selector: "Vendor Name",
      sortable: true,
      wrap:true,
    },
    {
      name: "Cost Center",
      selector: "Cost Center",
      sortable: true,
      wrap:true,
    },
    {
      name: "Cost Center Name",
      selector: "Cost Center Name",
      sortable: true,
      wrap:true,
    },
    {
      name: "Cost Element",
      selector: "Cost Element",
      sortable: true,
      wrap:true,
    },
    {
      name: "Cost Element Name",
      selector: "Cost Element Name",
      sortable: true,
      wrap:true,
    },
    {
      name: "Cost Element Desc",
      selector: "Cost Element Desc",
      sortable: true,
      wrap:true,
    },
    {
      name: "IT Function",
      selector: "IT Function",
      sortable: true,
      wrap:true,
    },
    {
      name: "Rollup Scorecard",
      selector: "Rollup Scorecard",
      sortable: true,
      wrap:true,
    },
    {
      name: "Cost Element Sub Category",
      selector: "Cost Element Sub Category",
      sortable: true,
      wrap:true,
    },
    {
      name: "",
      selector: "",

      cell: (row) =>
        <button
            className="btn btn-primary"
            onClick={(e) => {
              this.setState({show:true});
              this.bindPoPUpData(row.EntityId,row.LineNo);
            }}
           
          >Details
          </button>    
    },
  ];

  
  //Will define columns for datatable for other
  columnsOtherTransType = [
    {
      name: "PO/Reference Id",
      selector: "Purchasing Document",
      sortable: true,
      wrap:true,
      width:"150px"
    },
    {
      name: "Name",
      selector: "Name",
      sortable: true,
      wrap:true,
    },
    {
      name: "Cost Center",
      selector: "Cost Center",
      sortable: true,
      wrap:true,
    },
    {
      name: "Cost Center Name",
      selector: "Cost Center Name",
      sortable: true,
      wrap:true,
    },
    {
      name: "Cost Element",
      selector: "Cost Element",
      sortable: true,
      wrap:true,
    },
    {
      name: "Cost Element Name",
      selector: "Cost Element Name",
      sortable: true,
      wrap:true,
    },
    {
      name: "Cost Element Desc",
      selector: "Cost Element Desc",
      sortable: true,
      wrap:true,
    },
    {
      name: "IT Function",
      selector: "IT Function",
      sortable: true,
      wrap:true,
    },
    {
      name: "Rollup Scorecard",
      selector: "Rollup Scorecard",
      sortable: true,
      wrap:true,
    },
    {
      name: "",
      selector: "",
      cell: (row) =>
       <button
            className="btn btn-primary"
            onClick={(e) => {
              this.bindPoPUpData(row.EntityId,row.LineNo);
            }}
           
           >
             Details
          </button>
        
    },
  ];

  customStyles = {
    rows: {
      style: {
    	minHeight: '62px',
      fontSize: '14px'
      },
    	},
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor:"rgba(0,0,0,.12)"
        },
      },
    },
  };
  //will create data table and render on page
  getTableData = () => {
    return (
      <DataTableExtensions
        columns={
          this.state.TransType != "other"
            ? this.columns
            : this.columnsOtherTransType
        }
        data={this.state.filterData}
        export={false}
        print={false}
      >
        <DataTable
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          paginationPerPage={20}
          highlightOnHover
          paginationRowsPerPageOptions={[20, 50, 100, 200]}
          selectableRows={true}
          onSelectedRowsChange={this.updateState}
          customStyles={this.customStyles}
        />
      </DataTableExtensions>
    );
  };

  //this method will rest all session attribute values
  RestControls = () => {
    this.setState({
      isLoading: false,
      isItFunctionLoading: false,
      filterData: [],
      TransTypes: TransactionTypes,
      TransType: "",
      ITFunctions: [],
      ITFunction: "",
      ProcessType: "",
      showTable:false,
      show:false,
      transctionData:[],
      showLoadinggif:false,
      selectedRows:[]
    });
  };

  handleSelectcodeChange = (value, label, type) => {
    try {
      this.setState({ [type]: value });
      if (type == "TransType") {
        this.setState({
          ITFunctions: [],
          ITFunction: "",
          ProcessType: "",
          filterData: [],
          selectedRows:[]
        });
        this.getITFunctions(value);
      }
      if(type == "ITFunction")
      {
        this.setState({
          filterData: [],
          selectedRows:[]
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //in this method ae are setting value for service Drop down based on session attribute
  settransType_values = () => {
    try {
      return this.state.TransTypes.filter(
        (e) => e.value === this.state.TransType
      );
    } catch (error) {
      console.log(error);
    }
  };

  setITFunction_values = () => {
    try {
      return this.state.ITFunctions.filter(
        (e) => e.value === this.state.ITFunction
      );
    } catch (error) {
      console.log(error);
    }
  };

  setProcessTypes_values = () => {
    try {
      return this.state.ProcessTypes.filter(
        (e) => e.value === this.state.ProcessType
      );
    } catch (error) {
      console.log(error);
    }
  };

  //to get control based on transactionType
  getcontrol = () => {
    if (this.state.TransType != "other") {
      return (
        <Select
          isLoading={this.state.isItFunctionLoading}
          options={this.state.ITFunctions}
          value={this.setITFunction_values()}
          onChange={(e) => {
            this.handleSelectcodeChange(
              e !== null ? e.value : "",
              e !== null ? e.label : "",
              "ITFunction"
            );
          }}
        />
      );
    } else {
      return (
        <input
          className="amountInput form-control"
          id={"txtDummyItfunction"}
          type="text"
          disabled
        />
      );
    }
  };
//Reprocess api call
  Reprocess = () => {
    if (this.state.selectedRows.length > 0) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="conformDiv">
                    <div class="WarningMessageMain">
                      <img src={warningIcon} alt="warning" />
                      <h4>Warning!</h4>
                    </div>
                    <p className="conformBody" style={{textAlign:"center"}}>
                      Are you sure you want to Re-Process?
                    </p>
                    <div className="ClsSearchPOsButton">
                      <button
                        onClick={() => {
                          onClose();
                          this.setState({ isLoading: true });
                          let entityArray = [];
                          this.state.selectedRows.forEach((row) => {
                            var obj = { EntityId: row.EntityId };
                            entityArray.push(obj);
                          });
                          var processRuleObject = {
                            transType: this.state.TransType,
                            processType: this.state.ProcessType,
                            splitData: entityArray,
                            createdBy: this.getName(),
                            comments: "Transaction was re-processed from GUI",
                          };
                          axios
                            .post(
                              config.baseURL + "ReprocessTransacton",
                              processRuleObject
                            )
                            .then((response) => {
                              // handle success
                              this.setState({ isLoading: false });
                              document.getElementById(
                                "submittedPopup"
                              ).style.display = "block";
                              document.getElementById(
                                "div-overlay"
                              ).style.display = "block";
                            })
                            .catch((error) => {
                              // handle error
                              console.log(error);
                              this.setState({ isLoading: false });
                            });
                        }}
                        className="Clsbutton ClsSearch"
                      >
                        Yes
                      </button>
                      <button onClick={onClose} className="Clsbutton ClsReset">
                        No
                      </button>
                    </div>
                  </div>
                );
              },
            });
          } else {
            toast.error(messages.ReprocessTransactionRequired, {
              autoClose: 10000,
            });
          }
    };

  updateState = (stateObj) => {
    this.setState(stateObj);
  };

  render() {
    return (
      <div>
        <LoadingOverlay active={this.state.isLoading} spinner>
          {this.state.isLoading === true ? (
            ""
          ) : (
            <div class="body-support-block">
             <NavBarComponent updateState={this.updateState} activeTab = 'ReProcessTXN'/>
              <div class="tabcontent1">
                <div class="container-fluid">
                  <div class="row">
                    <div class="cardsContainer">
                      <div class="responsive-table ClsSearchList">
                        <div class="ClsClosingDateList1">
                          {" "}
                          <label for="lname">
                            Transaction Type<span class="required"> *</span>
                          </label>
                          <Select
                            options={this.state.TransTypes}
                            value={this.settransType_values()}
                            onChange={(e) => {
                              this.handleSelectcodeChange(
                                e !== null ? e.value : "",
                                e !== null ? e.label : "",
                                "TransType"
                              );
                            }}
                          />
                        </div>
                        <div className={"ClstableList"}>
                          {" "}
                          <label for="lname">
                            IT Function <span className={this.state.TransType!="other"?"required":"hidden"}> *</span>
                          </label>
                          {this.getcontrol()}
                        </div>
                        <div
                          className={
                            this.state.TransType == "other"
                              ? "ClstableList"
                              : "hidden"
                          }
                        >
                          {" "}
                          <label for="lname">
                            Process Type <span class="required"> *</span>
                          </label>
                          <Select
                            options={this.state.ProcessTypes}
                            value={this.setProcessTypes_values()}
                            onChange={(e) => {
                              this.handleSelectcodeChange(
                                e !== null ? e.value : "",
                                e !== null ? e.label : "",
                                "ProcessType"
                              );
                            }}
                          />
                        </div>

                        <div
                          style={{ marginRight: "0px" }}
                          className={"updateRuleSearch ReprocessSearchbtngrp"}
                        >
                          <button
                            class="Clsbutton ClsSearch"
                            onClick={() => this.getUnassignedTransaction()}
                          >
                            Search
                          </button>
                          <button
                            class="Clsbutton ClsReset"
                            onClick={() => this.RestControls()}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
      
                  <div className={this.state.showTable?"row mt-10":"hidden"}>
                    <div class="cardsContainer">
                      <div
                        className="responsive-table tableSearchPlacement  unassignedExport tableReprocess"
                        style={{ position: "relative" }}
                      >
                        <div class="exportBtnGroup">
                          <span className="input-group-btn left0"></span>
                          <button
                            class="btn btn-primary"
                            onClick={(e) => {
                              this.Reprocess();
                            }}
                          >
                            Re-Process
                          </button>
                        </div>
                        {this.getTableData()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          )}
        </LoadingOverlay>
        <SharedModel show={this.state.show} closePopup={this.closePopup} tableData={this.state.transctionData} showLoadinggif={this.state.showLoadinggif}>
          
          </SharedModel>
        <div className="modal show" id="submittedPopup">
          <div class="modal-dialog modal-success" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="successMessageMain">
                  <img src={popupIcon} alt="popup" />
                  <h4>Successfully Updated!</h4>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => this.closeSubmittedPopup()}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
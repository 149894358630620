import React from "react";
function Footer() {
  return (
    <footer className="footer mt-4 d-flex">
      <div className="cert-pad"> &copy;</div>
      <div className="foot-text">2021 Elanco</div>
    </footer>
  );
}
export default Footer;

import React, { Component } from "react";
import * as config from "../../config";
import * as messages from "../../messages.js";
import axios from "../../api.js";
import popupIcon from "../SplitComponent/tick-icon.png";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { msalInstance } from "../../authConfig";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTooltip from "react-tooltip";
toast.configure();
export default class AwaitingforApproval extends Component {
  constructor() {
    super();
    this.state = {
      transId: 0,
      isLoading: true,
      isddlLoading: false,
      l1Code: "",
      l1Value: "",
      l2Code: "",
      l2Value: "",
      pdAllocationCode: "",
      pdAllocationCodeDesc: "",
      comments: "",
      transctionData: [],
      porefferenceId: "",
      systemgeneratedRefID: "",
      podisabled: false,
      title: "Accept/Reject",
      isfrom: "",
      isEdit: false,
      commentsArray: [],
      LineId: "",
    };
  }

  //this method will call when page loads and load data to required controls
  componentDidMount = () => {
    let id = new URLSearchParams(window.location.search).get("id");
    let lineNumber = new URLSearchParams(window.location.search).get("LineId");
    this.setState({ transId: id });
    this.setState({ LineId: lineNumber });

    this.bindTransactionData(id, lineNumber);
    this.bindData(id, lineNumber);
  };
// this will get name for the loggedin user
  getName = () => {
    try {
      const name =
        msalInstance.getAllAccounts()[0] !== null
          ? msalInstance.getAllAccounts()[0].username
          : "";
      return name;
    } catch (error) {
      console.log(error);
    }
  };
  //this method will get data for Po Transaction meta data on right hand side
  bindTransactionData = (transId, lineno) => {
    try {
      var obj = {
        entityId: transId,
        linenumber:
          lineno !== null && lineno !== "" && lineno !== undefined ? lineno : 0,
      };
      axios
        .post(config.baseURL + "getPODetails", obj)
        .then((response) => {
          this.setState({ transctionData: response.data[0] });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
//this method will get data from Potransaction table by id and bind to requried session attributes
  bindData = (transId, lineno) => {
    try {
      var obj = {
        entityId: transId,
        linenumber:
          lineno !== null && lineno !== "" && lineno !== undefined ? lineno : 0,
      };
      axios
        .post(config.baseURL + "getApprovedTransactionById", obj)
        .then((response) => {
          this.setState({
            porefferenceId: response.data[0].PurchasingDocument,
          });
          this.bindCommentsHistory(
            response.data[0].PurchasingDocument,
            lineno,
            transId
          );
          this.setState({ l1Code: response.data[0].ServiceLineL1 });
          this.setState({ l2Code: response.data[0].ServiceL2 });
          this.setState({ pdAllocationCode: response.data[0].AllocationCode });
          this.setState({ pdAllocationCodeDesc: response.data[0].AllocationDesc });
          
          //this.setState({ comments: response.data[0].comments});
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
//this method will get the comments hisory and bind to required session Attrubutes
  bindCommentsHistory = (PoNumber, lineNumber, entityId) => {
    try {
      var obj = {
        PoNumber: PoNumber,
        LineId: lineNumber,
        EntityId: entityId,
      };
      axios
        .post(config.baseURL + "getCommentsHistory", obj)
        .then((response) => {
          this.setState({ commentsArray: response.data });
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
//this method will handle inputCommentChange event called when we enter data in to comments and updte the required session attribute
  handleCommentsChange = (e) => {
    try {
      this.setState({ comments: e.target.value });
    } catch (error) {
      console.log(error);
    }
  };
//halde cancel click in the page
  cancelClick = () => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="conformDiv">
              <p className="conformBody">
                If you cancel then your changes will be lost. Are you sure you
                want to cancel?
              </p>
              <div className="ClsSearchPOsButton">
                <button
                  onClick={() => {
                    window.location.href = "/ApprovalList";
                  }}
                  className="Clsbutton ClsSearch"
                >
                  Yes
                </button>
                <button onClick={onClose} className="Clsbutton ClsReset">
                  No
                </button>
              </div>
            </div>
          );
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
//this method will call api to save data from UI to DB
  submitData = () => {
    try {
      if (this.validateForm()) {
        const insertObject = {};
        insertObject.EntityId = this.state.transId;
        insertObject.IsApproved = 1;
        insertObject.Comments = this.state.comments.trim();
        insertObject.UserName = this.getName();
        this.setState({ isLoading: true });
        axios
          .post(config.baseURL + "UpdateApproveStatus", insertObject)
          .then((response) => {
            // handle success
            this.setState({ isLoading: false });
            document.getElementById("submittedPopup").style.display = "block";
            document.getElementById("div-overlay").style.display = "block";
          })
          .catch((error) => {
            // handle error
            this.setState({ isLoading: false });
            toast.error("Submit Failed", {
              autoClose: 10000,
            });
          });
      } else {
        toast.error(messages.commentsRequired, {
          autoClose: 10000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
//this method handles functionlity when you close the submit popup
  closeSubmittedPopup() {
    try {
      document.getElementById("submittedPopup").style.display = "none";
      document.getElementById("div-overlay").style.display = "none";
      window.location.href = "/ApprovalList";
    } catch (error) {
      console.log(error);
    }
  }
//this method validate the form based on business functionalities
  validateForm = () => {
    try {
      var result = true;
      if (this.state.l1code === "") {
        result = false;
      }
      if (this.state.l2code === "") {
        result = false;
      }
      if (this.state.pdAllocationCode === "") {
        result = false;
      }
      if (this.state.comments.trimStart().trimEnd() === "") {
        result = false;
      }

      return result;
    } catch (error) {
      console.log(error);
    }
  };
//this method will redirect to required page based on user rejction or approved
  RedirectToAssignL2 = () => {
    try {
      var cmnts = this.state.comments.trimStart().trimEnd();

      if (cmnts !== "") {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="conformDiv">
                <p className="conformBody">Are you sure you want to Reject?</p>
                <div className="ClsSearchPOsButton">
                  <button
                    onClick={() => {
                      const insertObject = {};
                      insertObject.EntityId = this.state.transId;
                      insertObject.IsApproved = 0;
                      insertObject.Comments =
                        "Reason for rejection: " +
                        this.state.comments.trimStart().trimEnd();
                      insertObject.UserName = this.getName();
                      this.setState({ isLoading: true });
                      axios
                        .post(
                          config.baseURL + "UpdateApproveStatus",
                          insertObject
                        )
                        .then((response) => {
                          // handle success
                          window.location.href =
                            "/AssignL2?id=" +
                            this.state.transId +
                            "&isFrom=approval&isEdit=true&lineno=" +
                            this.state.LineId;
                        })
                        .catch((error) => {
                          // handle error
                          this.setState({ isLoading: false });
                          toast.error(
                            "Rejection Failed due to some technical issue.Please Reach out to admin.",
                            {
                              autoClose: 10000,
                            }
                          );
                        });
                    }}
                    className="Clsbutton ClsSearch"
                  >
                    Yes
                  </button>
                  <button onClick={onClose} className="Clsbutton ClsReset">
                    No
                  </button>
                </div>
              </div>
            );
          },
        });
      } else {
        toast.error(messages.commentsRequired, {
          autoClose: 10000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <div>
        <LoadingOverlay active={this.state.isLoading} spinner>
          {this.state.isLoading === true ? (
            ""
          ) : (
            <div class="body-support-block">
              <div id="Unassigned" class="tabcontent tabcontent1">
                <div class="container-fluid splitAssignContainerFluid">
                  <div class="row">
                    <div class="cardsContainer bodyContentHeight">
                      <div class="responsive-table">
                        <div class="ClsSplitLeft">
                          <div class="ClsSplitTitle">{this.state.title}</div>
                          <div class="ClsAssigndiv">
                            <div class="ClsSplitAddDiv">
                              <div class="col-2">
                                <label for="lname">PO/Reference Id</label>
                                <input
                                  type="text"
                                  value={this.state.porefferenceId}
                                  class="form-control"
                                  disabled
                                />
                              </div>
                              <div class="col-3">
                                <label for="lname">Service Line</label>
                                <input
                                  type="text"
                                  value={this.state.l1Code}
                                  class="form-control"
                                  disabled
                                />
                              </div>
                              <div class="col-3">
                                <label for="lname">Service</label>
                                <input
                                  type="text"
                                  value={this.state.l2Code}
                                  class="form-control"
                                  disabled
                                />
                              </div>
                              <div class="col-3">
                                {" "}
                                <label for="lname">Allocation Code</label>
                                <span data-for="AllocDescApproval" data-tip={this.state.pdAllocationCodeDesc}>
                                <input
                                  className="amountInput form-control"
                                  type="text"
                                  value={this.state.pdAllocationCode}
                                  disabled
                                />
                                </span>
                                <ReactTooltip
                                            aria-haspopup="true"
                                            role="example"
                                            className="extraClass"
                                            id="AllocDescApproval"
                                            multiline="true"
                                            type="dark"
                                          ></ReactTooltip>
                              </div>
                            </div>
                            <div class="ClsSplitCommentsArea">
                              <div class="">
                                {" "}
                                <label for="lname">
                                  Comments<span class="required"> *</span>
                                </label>{" "}
                                <textarea
                                  value={this.state.comments}
                                  maxLength="3000"
                                  rows="4"
                                  cols="50"
                                  name="comment"
                                  form="usrform"
                                  class="form-control"
                                  onChange={(e) => {
                                    this.handleCommentsChange(e);
                                  }}
                                >
                                  {" "}
                                </textarea>{" "}
                              </div>
                            </div>
                            <div class="">
                              {this.state.commentsArray.map((cmntsObj, idx) => (
                                <div class="commentSingle">
                                  <div class="row">
                                    <div class="col-md-8">
                                      <h4>{cmntsObj.CreatedBy}</h4>
                                      <p class="textBreak">
                                        {cmntsObj.Comment}
                                      </p>
                                    </div>
                                    <div class="col-md-4">
                                      <p class="commentDate">
                                        {cmntsObj.CreatedDate}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div class="ClsSearchPOsButton">
                            <button
                              class="Clsbutton ClsSearch"
                              onClick={() => this.submitData()}
                              disabled={this.state.isSaveDisabled}
                            >
                              Accept
                            </button>
                            <button
                              class="Clsbutton rejectBtn"
                              onClick={() => this.RedirectToAssignL2()}
                            >
                              Reject
                            </button>
                            <button
                              class="Clsbutton ClsReset"
                              onClick={() => this.cancelClick()}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                        <div class="ClsSplitRight">
                          <div class="ClsSplitTitle">PO Details</div>
                          <div class="ClsSplitDiv">
                            <ul>
                              {this.state.transctionData.map((value, index) => {
                                return (
                                  <li>
                                    <span>{value.Columns}</span>
                                    <span>{value.Values}</span>{" "}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </LoadingOverlay>

        <div className="modal show" id="submittedPopup">
          <div class="modal-dialog modal-success" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="successMessageMain">
                  <img src={popupIcon} alt="popup" />
                  <h4>Approved Successfully!</h4>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => this.closeSubmittedPopup()}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

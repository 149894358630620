import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
import "./index.css";
import axios from "axios";
import * as config from './config.js';
import "./assests/css/styles.css";
import "font-awesome/css/font-awesome.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, loginRequest } from "./authConfig";
import * as serviceWorker from "./serviceWorker";

export const msalInstance = new PublicClientApplication(msalConfig);

new Promise(async (resolve, reject) => {
  await msalInstance.handleRedirectPromise().then(
    async () => {
      const accounts = await msalInstance.getAllAccounts();
      const currentAccount = accounts[0];
      const silentAccessTokenRequest = {
        scopes: loginRequest.scopes,

        account: currentAccount,

        forceRefresh: false,
      };

      let accessToken =
        currentAccount &&
        (await msalInstance
          .acquireTokenSilent(silentAccessTokenRequest)
          .then((accessTokenResponse) => accessTokenResponse.accessToken));
      console.log("accounts --" + accounts.length);

      if (accounts.length === 0) {
        // No user signed in

        await msalInstance
          .loginRedirect(loginRequest)

          .then(
            (res) => {
              console.log("inside index wrapper function");
              axios.defaults.baseURL =config.baseURL;
              axios.interceptors.request.use( response=>{
                response.headers['Authorization'] = 'Bearer ' + accessToken;
                 return response;
                 });

              ReactDOM.render(
                <React.StrictMode>
                  <MsalProvider instance={msalInstance}>
                    <App />
                  </MsalProvider>
                </React.StrictMode>,
                document.getElementById("root")
              );

              resolve();
            },
            (err) => {
              reject(err);

              console.error("error in " + err);
            }
          );
      } else {
        axios.defaults.baseURL =config.baseURL;
              axios.interceptors.request.use( response=>{
                response.headers['Authorization'] = 'Bearer ' + accessToken;
                 return response;
                 });
        ReactDOM.render(
          <React.StrictMode>
            <MsalProvider instance={msalInstance}>
              <App />
            </MsalProvider>
          </React.StrictMode>,
          document.getElementById("root")
        );

        resolve();
      }
    },
    (err) => {
      reject(err);

      console.error("error " + err);
    }
  );
});


serviceWorker.unregister();

import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import axios from "../../api.js";
import * as config from "../../config";
import * as messages from "../../messages.js";
import NavBarComponent from "../NavBarComponent/NavBarComponent.jsx";
import { confirmAlert } from "react-confirm-alert";
import warningIcon from "../MCGL2MappingComponent/Warning.png";
import { msalInstance } from "../../authConfig";
import popupIcon from "../SplitComponent/tick-icon.png";
import moment from "moment";

export default function TBMMasterFilter() {
  const [isLoading, setLoading] = useState(true);
  const [comment, setComment] = useState("");
  const [rollupScoreCards, setRollupScoreCards] = useState([]);
  const [activeRollupScoreCards, setActiveRollupScoreCards] = useState([]);
  const [activeRollupScoreCardCount, setActiveRollupScoreCardCount] =
    useState(0);

  const [rollupScorecardSlot1StartTime, setRollupScorecardSlot1StartTime] =
    useState(null);
  const [rollupScorecardSlot1EndTime, setRollupScorecardSlot1EndTime] =
    useState(null);
  const [rollupScorecardSlot2StartTime, setRollupScorecardSlot2StartTime] =
    useState(null);
  const [rollupScorecardSlot2EndTime, setRollupScorecardSlot2EndTime] =
    useState(null);
  const [timeFrameMessage, setTimeFrameMessage] = useState("");

  useEffect(() => {
    axios
      .get(config.baseURL + "getMasterFilterRollupScorecards")
      .then((response) => {
        updateRollupScoreCards(response);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const updateRollupScoreCards = (response) => {
    setLoading(false);
    setRollupScoreCards([]);
    if (response && response.data && response.data.length === 2) {
      setRollupScoreCards(response.data[0]);
      let rollupScorecard = response.data[0]
        .filter((scorecard) => scorecard.Active)
        .map(function (item) {
          return item.RollupScorecard;
        });
      setActiveRollupScoreCards(rollupScorecard);
      setActiveRollupScoreCardCount(rollupScorecard.length);

      let timeFrame = {};
      response.data[1].forEach((time) => {
        timeFrame[time.RuleColumn] = time.RuleValues;
      });

      let slot1Time = getLocalTimes(
        timeFrame.RollupScorecardSlot1StartTime,
        timeFrame.RollupScorecardProcessTimeLoadTime
      );
      setRollupScorecardSlot1StartTime(slot1Time.startTimeLocal);
      setRollupScorecardSlot1EndTime(slot1Time.endTimeLocal);

      let slot2Time = getLocalTimes(
        timeFrame.RollupScorecardSlot2StartTime,
        timeFrame.RollupScorecardProcessTimeLoadTime
      );
      setRollupScorecardSlot2StartTime(slot2Time.startTimeLocal);
      setRollupScorecardSlot2EndTime(slot2Time.endTimeLocal);

      let message = `TBM Master filter cannot be adjusted between ${slot1Time.startTimeLocal.format(
        "LT"
      )} and ${slot1Time.endTimeLocal.format(
        "LT"
      )} and ${slot2Time.startTimeLocal.format(
        "LT"
      )} and ${slot2Time.endTimeLocal.format("LT")}.`;
      setTimeFrameMessage(message);
    }
  };

  const getLocalTimes = (time, processLoadTime) => {
    let slotStartTime = time.split(":");
    let startTimeLocal = moment()
      .utc()
      .set({
        hour: parseInt(slotStartTime[0]),
        minute: parseInt(slotStartTime[1]),
        second: 0,
        millisecond: 0,
      })
      .local();
    let endTimeLocal = moment()
      .utc()
      .set({
        hour: parseInt(slotStartTime[0]) + parseInt(processLoadTime),
        minute: parseInt(slotStartTime[1]),
        second: 0,
        millisecond: 0,
      })
      .local();
    return { startTimeLocal: startTimeLocal, endTimeLocal: endTimeLocal };
  };
  const confirmBeforeSave = () => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="conformDiv">
              <div class="WarningMessageMain">
                <img src={warningIcon} alt="warning" />
                <h4>Warning!</h4>
              </div>
              <p className="conformBody" style={{ textAlign: "center" }}>
                Are you sure you want to make adjustments to the master filter?
                The new filter rule will become effective within the next 12
                hours.
              </p>
              <p>Note: {timeFrameMessage}</p>
              <div className="ClsSearchPOsButton">
                <button
                  onClick={() => {
                    onClose();
                    updateMasterFilter();
                  }}
                  className="Clsbutton ClsSearch"
                >
                  Yes
                </button>
                <button onClick={onClose} className="Clsbutton ClsReset">
                  No
                </button>
              </div>
            </div>
          );
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (event) => {
    let value = event.target.value;
    if (event.target.checked) {
      setActiveRollupScoreCards([...activeRollupScoreCards, value]);
    } else {
      setActiveRollupScoreCards(
        activeRollupScoreCards.filter(
          (rollupScoreCards) => rollupScoreCards !== value
        )
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      confirmBeforeSave();
    }
  };

  const reset = () => {
    setActiveRollupScoreCards(
      rollupScoreCards
        .filter((scorecard) => scorecard.Active)
        .map(function (item) {
          return item.RollupScorecard;
        })
    );
    setComment("");
  };
  // this will get name for the logged-in user
  const getName = () => {
    try {
      var email =
        msalInstance.getAllAccounts()[0] !== null
          ? msalInstance.getAllAccounts()[0].username
          : "";

      return email;
    } catch (error) {
      console.log(error);
    }
  };
  const updateMasterFilter = () => {
    try {
      let currentTime = moment().local();

      let slot1 = currentTime.isBetween(
        rollupScorecardSlot1StartTime,
        rollupScorecardSlot1EndTime
      );

      let slot2 = currentTime.isBetween(
        rollupScorecardSlot2StartTime,
        rollupScorecardSlot2EndTime
      );

      if (slot1 || slot2) {
        toast.error(timeFrameMessage, {
          autoClose: 10000,
        });
      } else {
        setLoading(true);
        axios
          .post(config.baseURL + "saveMasterFilter", {
            rollupScorecard: activeRollupScoreCards.join(","),
            comment: comment.trim(),
            createdBy: getName(),
          })
          .then((response) => {
            setLoading(false);
            reset();
            updateRollupScoreCards(response);
            document.getElementById("submittedPopup").style.display = "block";
            document.getElementById("div-overlay").style.display = "block";
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    } catch (error) {}
  };

  const validateForm = () => {
    let isValid =
      activeRollupScoreCards.length - activeRollupScoreCardCount > 0;
    if (comment.trim().length === 0) {
      toast.error(messages.commentRequired, {
        autoClose: 10000,
      });
      isValid = false;
    }
    return isValid;
  };

  //this method handles functionlity when you close the submit popup
  const closeSubmittedPopup = () => {
    document.getElementById("submittedPopup").style.display = "none";
    document.getElementById("div-overlay").style.display = "none";
  };

  return (
    <div>
      <LoadingOverlay active={isLoading} spinner>
        {isLoading ? (
          ""
        ) : (
          <div class="body-support-block">
            <NavBarComponent activeTab="MasterFilter" />
            <div id="tabcontent1" class="tabcontent tabcontent1">
              <form onSubmit={handleSubmit}>
                <div class="container-fluid">
                  <div class="row">
                    <div class="cardsContainer">
                      <div class="row">
                        <div class="col-12">
                          <label htmlFor="rollupScoreCard">
                            Roll-up ScoreCard
                          </label>
                          <div class="row p-1">
                            {rollupScoreCards.map((option, index) => {
                              return (
                                <div class="col-lg-3 col-md-4 mb-3">
                                  <div class="form-check checkbox-xl">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      name="rollupScoreCards"
                                      id={"checkbox-" + index}
                                      value={option.RollupScorecard}
                                      disabled={option.Active}
                                      checked={activeRollupScoreCards.includes(
                                        option.RollupScorecard
                                      )}
                                      onChange={(event) => {
                                        handleCheckboxChange(event);
                                      }}
                                    />
                                    <label
                                      class="form-check-label"
                                      htmlFor={"checkbox-" + index}
                                    >
                                      {option.RollupScorecard}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col">
                          <label htmlFor="comments">
                            Comments<span class="required"> *</span>
                          </label>
                          <textarea
                            rows="4"
                            cols="50"
                            maxLength="3000"
                            name="comments"
                            class="form-control"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="ui-btn">
                            <button
                              class="Clsbutton ClsSearch btn-tbm"
                              type="submit"
                              disabled={
                                activeRollupScoreCards.length -
                                  activeRollupScoreCardCount ===
                                0
                                  ? true
                                  : false
                              }
                            >
                              Save
                            </button>
                            <button
                              class="Clsbutton ClsReset"
                              type="button"
                              onClick={reset}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </LoadingOverlay>
      <div className="modal show" id="submittedPopup">
        <div class="modal-dialog modal-success" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="successMessageMain">
                <img src={popupIcon} alt="popup" />
                <h4>Successfully Updated!</h4>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() => closeSubmittedPopup()}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import dataAugIcon from "../LandingComponent/Data-Augmentation-icon.svg";
import dashboardIcon from "../LandingComponent/Dashboard-icon.svg";
import aboutIcon from "../LandingComponent/About-icon.svg";
import * as config from "../../config";



export default class LandingPage extends Component {
  openUrl=(url)=> {
   window.open(url,'_blank')
 }
  render() {
    return (
      <div class="landing-page">
        <div class="ClsLandingPage">
          <div class="landing-products">
            <ul>
              <li>
                <a data-attr-role="cd" href="/UnAssignedPOList">
                  <img src={dataAugIcon} alt="dataAugIcon"/>{" "}
                  <p>
                    <span>Data Augmentation</span>
                  </p>
                </a>
              </li>
              <li>
                <a data-attr-role="ct" href="#" onClick={(e)=>this.openUrl(config.powerBiURL)}>
                  <img src={dashboardIcon} alt="Dashboard" />{" "}
                  <p>
                    <span>Dashboard and Reports</span>
                  </p>
                </a>
              </li>
              <li>
                <a data-attr-role="cn" href="/AboutTBM">
                  <img src={aboutIcon}  alt="About"/>{" "}
                  <p>
                    <span>About this TBM Tool</span>
                  </p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import * as config from "../../config";
import * as messages from "../../messages.js";
import axios from "../../api.js";
import Select from "react-select";
import popupIcon from "../SplitComponent/tick-icon.png";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { msalInstance } from "../../authConfig";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTooltip from "react-tooltip";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Confirmation from "../SharedModel/ErrorMessage";

toast.configure();
export default class AssignL2 extends Component {
  constructor() {
    super();
    this.state = {
      transId: 0,
      isLoading: true,
      isddlLoading: false,
      l1Code: "",
      l1Value: "",
      l2Code: "",
      l2Value: "",
      pdAllocationCode: "",
      pdAllocationCodeDesc: "",
      L2CodeList: [],
      L1Codes: [],
      comments: "",
      transctionData: [],
      porefferenceId: "",
      systemgeneratedRefID: "",
      podisabled: false,
      title: "Assign Service",
      isfrom: "",
      isEdit: false,
      l2mappingId: "",
      commentsArray: [],
      software: "",
      softwareNames: [],
      softwareDisplay: false,
      isdatafetched: 0,
      vendorData: [],
      vendorName: "",
      vendorCode: "",
      vendorValue: [],
      isvndlLoading: false,
      postingDate: "",
      showErrorPopup: false,
      errorMessage: "",
      errorMessageContent: "",
      errorLogNumber: "",
      additionalErrorContent:""
    };
    this.handleL1codeChange = this.handleL1codeChange.bind(this);
  }
  //this method will call when page loads and load data to required controls
  componentDidMount = () => {

    let id = new URLSearchParams(window.location.search).get("id");
    let Edit =
      new URLSearchParams(window.location.search).get("isEdit") === "true"
        ? true
        : false;
    let isFrom = new URLSearchParams(window.location.search).get("isFrom");
    let linenumber = new URLSearchParams(window.location.search).get("lineno");
    let postingDate = new URLSearchParams(window.location.search).get(
      "postingDate"
    );
    linenumber = linenumber !== null ? linenumber : 0;
    setTimeout(() => {
      this.setState({ transId: id });
      this.setState({ isfrom: isFrom });
      this.setState({ isEdit: Edit });
    }, 200);

    this.bindTransactionData(id, linenumber, postingDate);
    this.bindSoftwareNames();
    this.bindL1Codes();
    this.VendorDetails();
    this.bindTransactionDataById(id, linenumber);


    if (Edit) {
      if (isFrom !== "approval") {
        this.setState({ podisabled: true });
        this.setState({ title: "ReAssign Service" });
      }
    } else {
      this.getLastRefferenceId();
    }
  };
  // this will get name for the loggedin user
  getName = () => {
    try {
      const name =
        msalInstance.getAllAccounts()[0] !== null
          ? msalInstance.getAllAccounts()[0].username
          : "";

      return name;
    } catch (error) {
    }
  };
  //this method will get data from Potransaction table by id and bind to requried session attributes
  bindTransactionDataById = (transId, lineno, postingDate) => {
    let postingDate1 = new URLSearchParams(window.location.search).get(
      "postingDate"
    );
    this.setState({ postingDate: postingDate1 })
    try {
      var obj = {
        entityId: transId,
        linenumber: lineno,
        postingDate: postingDate1,
      };

      axios
        .post(config.baseURL + "getTransactionForAssignScreen", obj)
        .then((response) => {
          if (response.data.length > 0) {
            this.setState({
              isdatafetched: 1,
            });
            this.bindCommentsHistory(
              response.data[0].PurchasingDocument,
              lineno,
              transId
            );
            if (
              response.data[0].PurchasingDocument !== null &&
              response.data[0].PurchasingDocument !== "" &&
              response.data[0].PurchasingDocument !== undefined
            ) {
              this.setState({
                porefferenceId: response.data[0].PurchasingDocument,
              });
              if (response.data[0].IsSystemGenerated === 1) {
                this.setState({
                  systemgeneratedRefID: response.data[0].PurchasingDocument,
                });
              }
            }
            this.setState({
              pdAllocationCode: response.data[0].AllocationCode,
              pdAllocationCodeDesc: response.data[0].AllocationDesc,

            });
            if (response.data[0].AllocationCode === "U4") {
              this.setState({
                softwareDisplay: true,
              });
            }
            this.setState({ software: response.data[0].SoftwareName });
            this.setState({ l1Code: response.data[0].ServiceLineL1 });
            this.setState({ l2Code: response.data[0].ServiceL2 });
            this.setState({ l2mappingId: response.data[0].TBMMGCCode });
            this.bindL2Codes(response.data[0].ServiceLineL1, "method");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
  //this method will get data for Po Transaction meta data on right hand side
  bindTransactionData = (transId, lineno) => {
    try {
      var obj = {
        entityId: transId,
        linenumber:
          lineno !== null && lineno !== "" && lineno !== undefined ? lineno : 0,
      };
      axios
        .post(config.baseURL + "getPODetails", obj)
        .then((response) => {
          console.log("getPODetails" + response);
          this.setState({ transctionData: response.data[0] });
          // this.setState({ postingDate: this.state.transctionData[9].Values })
          this.setState({ vendorCode: this.state.transctionData[1].Values })
          this.setState({ vendorName: this.state.transctionData[2].Values })
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
  //this method will get the comments hisory and bind to required session Attrubutes
  bindCommentsHistory = (PoNumber, lineNumber, EntityId) => {
    try {
      var obj = {
        PoNumber: PoNumber,
        LineId: lineNumber,
        EntityId: EntityId,
      };
      axios
        .post(config.baseURL + "getCommentsHistory", obj)
        .then((response) => {
          this.setState({ commentsArray: response.data });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
  //this method will get Service data and bind to required sesssion Attributes
  bindL1Codes = () => {
    try {
      axios
        .get(config.baseURL + "getL1Codes")
        .then((response) => {
          this.setState({ L1Codes: response.data });
          // if (!this.state.isEdit) {
          //   this.setState({ isLoading: false });
          // }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
  //this method will get Service Line data and bind to required sesssion Attributes
  bindL2Codes = (l1Code, frm) => {
    try {
      axios
        .get(config.baseURL + "getL2Codes/" + l1Code)
        .then((response) => {
          this.setState({ L2CodeList: response.data });

          if (this.state.isEdit && frm !== "handle") {
            this.getPdAllocationCode(this.state.l2mappingId);
          }
          this.setState({ isddlLoading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isddlLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
  //this method will get Allocation code based on mgccode when we select service and service line
  getPdAllocationCode = (MgcCode) => {
    try {
      axios
        .get(config.baseURL + "getPDAllocationCode/" + MgcCode)
        .then((response) => {
          if (response.data.length > 0) {
            this.setState({
              pdAllocationCode: response.data[0].AllocationCode,
              pdAllocationCodeDesc: response.data[0].AllocationDesc
            });
            if (
              response.data[0].AllocationCode !== null &&
              response.data[0].AllocationCode !== undefined
            ) {
              if (response.data[0].AllocationCode.trimEnd() === "U4") {
                this.setState({
                  softwareDisplay: true,
                });
              } else {
                this.setState({
                  softwareDisplay: false,
                });
                this.setState({
                  software: "",
                });
              }
            } else {
              this.setState({
                softwareDisplay: false,
              });
              this.setState({
                software: "",
              });
            }
          } else {
            this.setState({
              softwareDisplay: false,
            });
            this.setState({
              software: "",
            });
          }

          //this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //this method will get last system genrated refference id and based on the refference id will validate format of refference id which user entered from UI and Update IsSystem genrated Flag
  getLastRefferenceId = () => {
    try {
      axios
        .get(config.baseURL + "getLastRefferenceID")
        .then((response) => {
          if (response.data.length > 0) {
            var str = response.data[0].PurchasingDocument.substring(11);
            var incrementNumber = parseInt(str) + 1;

            str =
              "REF" +
              this.formattedUTCDate() +
              incrementNumber.toString().padStart(4, "0");
            this.setState({ porefferenceId: str });
            this.setState({ systemgeneratedRefID: str });
            this.setState({ isLoading: false });
          } else {
            str = "REF" + this.formattedUTCDate() + "000" + 1;
            this.setState({ porefferenceId: str });
            this.setState({ systemgeneratedRefID: str });
            this.setState({ isLoading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //this method will format the date to date string mm/dd/yyyy
  formattedUTCDate = () => {
    let month = String(new Date().getUTCMonth() + 1);
    let day = String(new Date().getUTCDate());
    const year = String(new Date().getUTCFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return `${year}${month}${day}`;
  };
  //this method will handle inputChange event called when we enter data and updte the required session attribute
  handleInputValueChange = (e) => {
    try {
      this.setState({ porefferenceId: e });
    } catch (error) {
      console.log(error);
    }
  };
  //this method will handle inputCommentChange event called when we enter data in to comments and updte the required session attribute
  handleCommentsChange = (e) => {
    try {
      this.setState({ comments: e });
    } catch (error) {
      console.log(error);
    }
  };
  //this method will handle Service Change event called when we select data and updte the required session attribute
  handleL1codeChange = (l1value, l1text) => {
    try {
      this.setState({ l1Code: l1text });
      this.setState({ isddlLoading: true });
      this.setState({ l2Code: "" });
      this.setState({ pdAllocationCode: "" });
      this.setState({ pdAllocationCodeDesc: "" });

      this.setState({ l2Value: "" });
      this.setState({
        software: "",
      });
      this.setState({
        softwareDisplay: false,
      });

      this.bindL2Codes(l1value, "handle");
    } catch (error) {
      console.log(error);
    }
  };
  //this method will handle Service line Change event called when we select data and updte the required session attribute
  handleL2codeChange = (mgcCode, l2text) => {
    try {
      this.setState({ l2mappingId: mgcCode })
      this.setState({ l2Code: l2text });
      setTimeout(() => {
        this.setState({ l2mappingId: mgcCode });
      }, 500);

      this.setState({ pdAllocationCode: "" });
      this.setState({ pdAllocationCodeDesc: "" });

      this.setState({
        software: "",
      });

      this.getPdAllocationCode(mgcCode);
    } catch (error) {
      console.log(error);
    }
  };
  //this method will call api to save data from UI to DB
  submitData = () => {
    try {
      const insertObject = {};
      insertObject.transId = this.state.transId;
      insertObject.isSysGenerated = this.checkPoFormat();
      insertObject.porefferenceId = this.state.porefferenceId
        .trimStart()
        .trimEnd();
      insertObject.comments = this.state.comments.trimStart().trimEnd();
      insertObject.createdBy = this.getName();
      insertObject.l2mappingId = this.state.l2mappingId;
      insertObject.screenName = "AssignL2";
      insertObject.serviceL2 = this.state.l2Code;
      insertObject.IsJE = 1;
      insertObject.software = this.state.software;
      insertObject.vendorcode = this.state.vendorCode;
      insertObject.postingDate = this.state.postingDate;
      var lineNumber = new URLSearchParams(window.location.search).get(
        "lineno"
      );

      insertObject.LineNo = lineNumber !== null ? lineNumber : 0;
      this.setState({ isLoading: true });
      axios
        .post(config.baseURL + "saveAssignReAssignL2", insertObject)
        .then((response) => {
          this.setState({ isLoading: false });
          let result = response && response.data && response.data.recordsets[0] && response.data.recordsets[0][0]? response.data.recordsets[0][0]: null;
          let result2 = response && response.data && response.data.recordsets[1] && response.data.recordsets[1][0]? response.data.recordsets[1][0]: null;
          if((result && result.State == 1)||(result2 && result2.State == 1)) {
            // handle success
            var frm = this.state.isfrom;
            var url = "";
            if (frm === "unAssigned") {
              url = "/UnAssignedPOList";
            } else if (frm === "approval") {
              url = "/ApprovalList";
            } else {
              url = "/SearchPo";
            }
            window.history.pushState("page2", "Title", url);
            document.getElementById("submittedPopup").style.display = "block";
            document.getElementById("div-overlay").style.display = "block";
          }
          else {
            this.setState({errorMessage: result.ErrorMessage ? result.ErrorMessage : "The Submit failed."});
            this.setState({errorMessageContent: result.ErrorMessageContent ? result.ErrorMessageContent : "The Submit failed."});
            this.setState({errorLogNumber: result.ErrorLogNumber ? result.ErrorLogNumber : ""});
            this.setState({additionalErrorContent: result.AdditionalErrorContent ? result.AdditionalErrorContent : ""});
            this.setState({showErrorPopup: true});
          }
        })
        .catch((error) => {
          // handle error
          this.setState({ isLoading: false });
          toast.error("The Submit failed.", {
            autoClose: 10000,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  //this method handles functionlity when you close the submit popup
  closeSubmittedPopup() {
    try {
      document.getElementById("submittedPopup").style.display = "none";
      document.getElementById("div-overlay").style.display = "none";
      var frm = this.state.isfrom;
      if (frm === "unAssigned") {
        window.location.href = "/UnAssignedPOList";
      } else if (frm === "approval") {
        window.location.href = "/ApprovalList";
      } else {
        window.location.href = "/SearchPo";
      }
    } catch (error) {
      console.log(error);
    }
  }
  //this method validate the form based on business functionalities
  validateForm = () => {
    try {
      var result = true;
      this.checkPoFormat();
      if (
        this.state.l1Code === "" ||
        this.state.l1Code === null ||
        this.state.l1Code === undefined
      ) {
        result = false;
      }
      if (
        this.state.l2Code === "" ||
        this.state.l2Code === null ||
        this.state.l2Code === undefined
      ) {
        result = false;
      }
      if (this.state.porefferenceId.trimStart().trimEnd() === "") {
        result = false;
      }
      if (this.state.comments.trimStart().trimEnd() === "") {
        result = false;
      }

      if (
        (this.state.software === "" ||
          this.state.software === null ||
          this.state.software === undefined) &&
        this.state.softwareDisplay
      ) {
        toast.error(messages.softwaremandatory, {
          autoClose: 10000,
        });
        return false;
      }
      if (!result) {
        toast.error(messages.validationMesage, {
          autoClose: 10000,
        });
      } else {
        if (this.state.isEdit === false) {
          this.setState({ isLoading: true });
          var obj = {
            refid: this.state.porefferenceId.trimStart().trimEnd(),
          };
          axios
            .post(config.baseURL + "checkDuplicateRefId", obj)
            .then((response) => {
              this.setState({ isLoading: false });
              var count = response.data.length > 0 ? response.data[0].count : 0;
              if (count > 0) {
                result = false;
                this.conformationForPONumber();
              } else {
                this.submitData();
              }
            })
            .catch((error) => {
              this.setState({ isLoading: false });
              console.log(error);
            });
        } else {
          this.submitData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //halde cancel click in the page
  cancelClick = () => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="conformDiv">
              <p className="conformBody">
                If you cancel then your changes will be lost. Are you sure you
                want to cancel?
              </p>
              <div className="ClsSearchPOsButton">
                <button
                  onClick={() => {
                    var frm = this.state.isfrom;
                    if (frm === "unAssigned") {
                      window.location.href = "/UnAssignedPOList";
                    } else if (frm === "approval") {
                      window.location.href = "/ApprovalList";
                    } else {
                      window.location.href = "/SearchPo";
                    }
                  }}
                  className="Clsbutton ClsSearch"
                >
                  Yes
                </button>
                <button onClick={onClose} className="Clsbutton ClsReset">
                  No
                </button>
              </div>
            </div>
          );
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  //in this method we are validating po number is already exixts
  conformationForPONumber = () => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="conformDiv">
              <p className="conformBody">
                PO/Reference Id already exists.Please click on refresh to
                regenerate new PO/Reference Id.
              </p>
              <div className="ClsSearchPOsButton">
                <button
                  onClick={() => {
                    this.setState({ isLoading: true });
                    onClose();
                    this.getLastRefferenceId();
                  }}
                  className="Clsbutton ClsSearch"
                >
                  Refresh
                </button>
                <button onClick={onClose} className="Clsbutton ClsReset">
                  Cancel
                </button>
              </div>
            </div>
          );
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  //in this method ae are setting value for service Drop down based on session attribute
  setl1_values = () => {
    try {
      return this.state.L1Codes.filter((e) => e.label === this.state.l1Code);
    } catch (error) {
      console.log(error);
    }
  };
  //in this method ae are setting value for service line Drop down based on session attribute
  setl2_values = () => {
    try {
      return this.state.L2CodeList.filter((e) => e.label === this.state.l2Code);
    } catch (error) {
      console.log(error);
    }
  };
  //in this method we are validation po number is  system generated or not
  checkPoFormat = () => {
    try {
      var result = 0;
      var posubstr = this.state.porefferenceId.substring(0, 11);
      var sysPosubstr = this.state.systemgeneratedRefID.substring(0, 11);
      if (posubstr === sysPosubstr) {
        var lastPostring = this.state.porefferenceId.substring(
          11,
          this.state.porefferenceId.length
        );
        var lastSysPostring = this.state.systemgeneratedRefID.substring(
          11,
          this.state.porefferenceId.length
        );
        if (
          this.isNumeric(lastSysPostring) &&
          parseInt(lastPostring) >= lastSysPostring
        )
          result = 1;
      }
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  //in this method we are  validating enter value is numeric or not
  isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  };
  //this method will get software from db by calling an api and bind it to required session attribute
  bindSoftwareNames = () => {
    axios
      .get(config.baseURL + "getServiceSoftwareNames")
      .then((response) => {
        var swarray = response.data;
        var otherobj = {
          label: "Other",
          value: "Other",
        };
        swarray.push(otherobj);

        this.setState({ softwareNames: swarray });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };
  //this method will return html based on  Software display session attribute
  getControl = () => {
    if (this.state.softwareDisplay) {
      return (
        <Select
          id={"ddl3"}
          value={this.setSoftware_values()}
          options={this.state.softwareNames}
          onChange={(e) => {
            this.handleSoftwarecodeChange(e !== null ? e.label : "");
          }}
        />
      );
    } else {
      return (
        <input
          className="amountInput form-control"
          id={"txtDummy"}
          type="text"
          disabled
        />
      );
    }
  };
  //this method will handle Software Change event called when we select data and updte the required session attribute
  handleSoftwarecodeChange = (SoftwareName, idx) => {
    try {
      setTimeout(() => {
        this.setState({ software: SoftwareName });
      }, 200);
    } catch (error) {
      console.log(error);
    }
  };
  //in this method we are setting value for Software Drop down based on session attribute
  setSoftware_values = () => {
    try {
      return this.state.softwareNames.filter(
        (e) => e.label === this.state.software
      );
    } catch (error) {
      console.log(error);
    }
  };
  // getting vendor Name 
  VendorDetails = () => {
    this.setState({ isLoading: true });
    this.setState({ isvndlLoading: true })

    axios
      .get(config.baseURL + "getVendorDetails")
      .then((response) => {
        console.log(response, "resss");
        setTimeout(() => { this.setState({ vendorData: response.data }) }, 300);
        /// this.setState({ vendorData: response.data });
        console.log(this.state.vendorData, "response.data")
        if (this.state.vendorData) {
          this.setState({ isvndlLoading: false })
        }
        console.log(this.state.vendorName, "vendor")
        // this.setState({  vendorValue: this.state.vendorData.find(obj => obj.value === this.state.vendorName)})
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isvndlLoading: false })
        this.setState({ isLoading: false });
      });
  };
  // get vendor code
  getVendorCode = (v) => {
    try {
      console.log(v, "eventevent")
      console.log(v, "vendor")
      this.setState({ vendorValue: v.value })
      axios
        .get(config.baseURL + "getVendorCode/" + v.value)
        .then((response) => {
          console.log(response);
          this.setState({ vendorCode: response.data[0].vendor });
          // this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.label,
    limit: 100,
  });

  render() {
    return (
      <div>
        <LoadingOverlay active={this.state.isLoading} spinner>
          {this.state.isLoading === true ? (
            ""
          ) : (
            <div class="body-support-block">
              <div id="Unassigned" class="tabcontent tabcontent1">
                <div class="container-fluid splitAssignContainerFluid">
                  <div class="row">
                    <div class="cardsContainer bodyContentHeight">
                      <div class="responsive-table">
                        <div class="ClsSplitLeft">
                          <div class="ClsSplitTitle">{this.state.title}</div>
                          <div class="ClsAssignReassigndiv">
                            <div class="ClsSplitAddDiv">
                              <div class="col-2 widtAssignRefID">
                                <label for="lname">
                                  PO/Reference Id
                                  <span class="required"> *</span>
                                </label>
                                <input
                                  type="text"
                                  value={this.state.porefferenceId}
                                  class="form-control"
                                  disabled={this.state.podisabled}
                                  maxLength="40"
                                  onChange={(e) => {
                                    this.handleInputValueChange(e.target.value);
                                  }}
                                />
                              </div>
                              <div class="col-3 widtAssignService">
                                <label for="lname">
                                  Service Line<span class="required"> *</span>
                                </label>
                                <Select
                                  options={this.state.L1Codes}
                                  value={this.setl1_values()}
                                  onChange={(e) => {
                                    this.handleL1codeChange(
                                      e !== null ? e.value : "",
                                      e !== null ? e.label : ""
                                    );
                                  }}
                                />
                              </div>
                              <div class="col-3 widtAssignService">
                                <label for="lname">
                                  Service<span class="required"> *</span>
                                </label>
                                <Select
                                  options={this.state.L2CodeList}
                                  isLoading={this.state.isddlLoading}
                                  value={this.setl2_values()}
                                  onChange={(e) => {
                                    this.handleL2codeChange(
                                      e !== null ? e.value : "",
                                      e !== null ? e.label : ""
                                    );
                                  }}
                                />
                              </div>
                              <div class="col-2 widtAssignAllocation"  >
                                {" "}
                                <label for="lname">Allocation Code</label>
                                <span data-for="AllocDescAssign" data-tip={this.state.pdAllocationCodeDesc}>
                                  <input
                                    className="amountInput form-control"
                                    type="text"
                                    value={this.state.pdAllocationCode}
                                    disabled
                                  />
                                </span>
                                <ReactTooltip
                                  aria-haspopup="true"
                                  role="example"
                                  className="extraClass"
                                  id="AllocDescAssign"
                                  multiline="true"
                                  type="dark"
                                ></ReactTooltip>
                              </div>
                              <div class="col-2">
                                {" "}
                                <label for="lname">Software Name</label>
                                {this.getControl()}
                              </div>
                            </div>
                            <div className="row m-2 ">
                              <div class="col-2"  >
                                <label for="lname">
                                  Parent MGC Code
                                </label>
                                <input
                                  className="amountInput form-control"
                                  id={"txtPD1"}
                                  type="text"
                                  value={this.state.l2mappingId}
                                  disabled
                                />
                              </div>
                              <div className="ClsSplitass3 splitselect-v autosearchul">
                                <label className="form-label-split ">
                                  Vendor Name
                                </label>
                                <Autocomplete
                                  className=" reactautofill autofilltextarea"
                                  freeSolo
                                  id="free-solo-demo"
                                  defaultValue={this.state.vendorName}
                                  value={this.state.vendorName}
                                  autoHighlight
                                  autoSelect={true}
                                  filterOptions={this.filterOptions}
                                  loading={this.state.isvndlLoading}
                                  openOnFocus
                                  filterSelectedOptions
                                  options={this.state.vendorData}
                                  onChange={(e, v) => this.getVendorCode(v)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label=""
                                      variant="outlined"
                                      placeholder="Select..."
                                      size="small"

                                    />
                                  )}
                                />
                              </div>
                              <div
                                className=" "
                              >
                                <label className="form-label-split">
                                  Vendor Code
                                </label>
                                <input
                                  className="amountInput form-control"
                                  type="text"
                                  value={this.state.vendorCode}
                                  disabled
                                />
                              </div>
                            </div>
                            <div class="ClsSplitCommentsArea">
                              <div class="">
                                {" "}
                                <label for="lname">
                                  Comments<span class="required"> *</span>
                                </label>{" "}
                                <textarea
                                  value={this.state.comments}
                                  rows="4"
                                  cols="50"
                                  maxLength="3000"
                                  name="comment"
                                  form="usrform"
                                  class="form-control"
                                  onChange={(e) => {
                                    this.handleCommentsChange(e.target.value);
                                  }}
                                >
                                  {" "}
                                </textarea>{" "}
                              </div>
                            </div>
                            <div class="">
                              {this.state.commentsArray.map((cmntsObj, idx) => (
                                <div class="commentSingle">
                                  <div class="row">
                                    <div class="col-md-8">
                                      <h4>{cmntsObj.CreatedBy}</h4>
                                      <p class="textBreak">
                                        {cmntsObj.Comment}
                                      </p>
                                    </div>
                                    <div class="col-md-4">
                                      <p class="commentDate">
                                        {cmntsObj.CreatedDate}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div class="ClsSearchPOsButton">
                            <button
                              style={{ opacity: this.state.savebtnOpacity }}
                              class="Clsbutton ClsSearch"
                              onClick={() => this.validateForm()}
                              disabled={this.state.isSaveDisabled}
                            >
                              Save
                            </button>
                            <button
                              class="Clsbutton ClsReset"
                              onClick={() => this.cancelClick()}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                        <div class="ClsSplitRight">
                          <div class="ClsSplitTitle">PO Details</div>
                          <div class="ClsSplitDiv">
                            <ul>
                              {this.state.transctionData.map((value, index) => {
                                return (
                                  <li>
                                    <span>{value.Columns}</span>
                                    <span>{value.Values}</span>{" "}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </LoadingOverlay>

        <div className="modal show" id="submittedPopup">
          <div class="modal-dialog modal-success" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="successMessageMain">
                  <img src={popupIcon} alt="popUp" />
                  <h4>Successfully Updated!</h4>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => this.closeSubmittedPopup()}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showErrorPopup ?
        (
          <div className="modalhow" id="errorPopup">
            <Confirmation 
              showConfirmation={this.state.showErrorPopup}
              ModalContent={this.state.errorMessageContent}
              handleClose={() => {
                this.setState({showErrorPopup:false})
              }}
              handleConfirm={() => {
                let copyMessage = `${this.state.errorMessage} | Log Number:${this.state.errorLogNumber} | Log Error:${this.state.additionalErrorContent}`
                navigator.clipboard.writeText(copyMessage);
              }}
              primaryButtonText = "Copy"
              secondaryButtonText="Close"
              additionalContent = {this.state.additionalErrorContent}
            />  
          </div>
        ):""}
      </div>
    );
  }
}
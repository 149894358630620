import * as config from './config.js';
import { PublicClientApplication } from "@azure/msal-browser";
export const msalConfig = {
    auth: {
        clientId: config.clientId,
        authority: 'https://login.microsoftonline.com/' + config.tenant,
        redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: [`${config.clientId}/.default`]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com"
  };

  export const msalInstance = new PublicClientApplication(msalConfig);
 

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./errormessage.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function Confirmation({
    showConfirmation = false,
    handleConfirm,
    handleClose,
    ModalContent,
    primaryButtonText,
    secondaryButtonText,
    additionalContent
}) {
    const [showMore, setShowMore] = useState(false);   
    return (
        <Modal className="modal-component delete-confirmation" show={showConfirmation} onHide={handleClose} backdrop="static">
            <Modal.Header className="confirm-modal-header" closeButton>
                <Modal.Title className="confirm-modal-title">Error Message</Modal.Title>
            </Modal.Header>
            <Modal.Body className=' confirm-modal-body'>
                <div dangerouslySetInnerHTML={{__html: ModalContent}} style={{wordWrap:"break-word"}}/>
                {additionalContent?.length>0 ?(
                    <div class="row">
                        <div class="col-10">
                            <hr className="horizontal-line" />
                        </div>
                        <div class="col-2 pl-0">
                            <button className="btn mb-0 p-0" onClick={() => setShowMore(!showMore)} >
                                {showMore ? "Show less" : "Show more"}
                            </button>
                        </div>
                    </div>
                 ):""}
                {showMore ?(
                    <div dangerouslySetInnerHTML={{__html: additionalContent}} style={{wordWrap:"break-word"}}/>
                ):""}
            </Modal.Body>
            <Modal.Footer className='border-top-0'>
                <div className="content w-100 d-flex justify-content-end ">
                    <Button
                        variant="primary"
                        className="copy-button btn-go-inner-dark btn-width40"
                        onClick={() => {
                            handleConfirm();
                        }}
                    >
                        {primaryButtonText}
                    </Button>
                    <Button
                        className="btn-go-inner-dark btn-width40"
                        variant="secondary"
                        onClick={() => handleClose()}
                    >
                        {secondaryButtonText}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default Confirmation;
import React, { Component } from "react";
import * as config from "../../config";
import * as messages from "../../messages.js";
import axios from "../../api.js";
import Select from "react-select";
import RemoveIcon from "../SplitComponent/Delete-icon.svg";
import popupIcon from "../SplitComponent/tick-icon.png";
import AddIcon from "../SplitComponent/add.svg";
import LoadingOverlay from "react-loading-overlay";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { msalInstance } from "../../authConfig";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTooltip from "react-tooltip";
import Autocomplete ,{createFilterOptions} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Confirmation from "../SharedModel/ErrorMessage";

toast.configure();

export default class SplitAssign extends Component {
  constructor() {
    super();
    this.state = {
      transId: 0,
      isLoading: true,
      totalAmount: 0,
      remainingAmount: 0.0,
      splitData: [
        {
          id: 1,
          amount: "",
          l1Code: "",
          l2Code: "",
          pdAllocationCode: "",
          pdAllocationCodeDesc: "",
          L2CodeList: [],
          showAddButton: true,
          disablebtn: true,
          defaultvalue: "Select L2 Name",
          opacity: ".5",
          isddlLoading: false,
          isAmountHighlight: false,
          l2mappingId: "",
          l2Id: "",
          software: "",
          softwareDisplay: false,
          softwarelist: [],
          isvndlLoading:false,
          showErrorPopup: false,
          errorMessage: "",
          errorMessageContent: "",
          errorLogNumber: "",
          additionalErrorContent:""
        },
      ],
      softwareNames: [],
      L1Codes: [],
      comments: "",
      transctionData: [],
      splitObjectArray: [],
      isSaveDisabled: false,
      savebtnOpacity: "1",
      isFrom: "",
      commentsArray: [],
      vendorData: [],
      vendorName: "",
      vendorCode: "",
      vendorValue: [],
      isvndlLoading:false
    };

    this.handleL1codeChange = this.handleL1codeChange.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
  }
  //this method will call when page loads and load data to required controls
  componentDidMount = () => {
    this.bindL1Codes();
    this.VendorDetails();
    let id = new URLSearchParams(window.location.search).get("id");
    let lineNumber = new URLSearchParams(window.location.search).get(
      "LineNumber"
    );
    let postingDate = new URLSearchParams(window.location.search).get(
      "PostingDate"
    );
    console.log(postingDate,"hh")
    this.bindSoftwareNames(id, lineNumber,postingDate);
    this.bindTransactionData(id,lineNumber)
  };
  // this will get name for the loggedin user
  getName = () => {
    try {
      var email =
        msalInstance.getAllAccounts()[0] !== null
          ? msalInstance.getAllAccounts()[0].username
          : "";

      return email;
    } catch (error) {
      console.log(error);
    }
  };
  //this method will get data from Potransaction table by id and bind to requried session attributes
  bindSplitAssignDetails = (EntityId, lineNumber,postingDate) => {
    try {
      var obj = {
        entityId: EntityId,
        linenumber: lineNumber,
        postingDate:postingDate,
        UserName: this.getName(),
      };
      console.log(postingDate,"obj")
      axios
        .post(config.baseURL + "getSplitOrAssignDetails", obj)
        .then((response) => {

          let nextSocialData = this.state.splitData.slice();
          nextSocialData[0].amount = response.data[0][0].PerformanceAmount;
          var mgcCode = response.data[0][0].TBMMGCCode;
          if (mgcCode !== "" && mgcCode !== null && mgcCode !== undefined) {
            nextSocialData[0].l1Code = response.data[0][0].ServiceLineL1;
            nextSocialData[0].l2Code = response.data[0][0].ServiceL2;
            nextSocialData[0].pdAllocationCode =
              response.data[0][0].AllocationCode;
            if (response.data[0][0].AllocationCode.trimEnd() === "U4") {
              nextSocialData[0].softwarelist = this.state.softwareNames;
              nextSocialData[0].softwareDisplay = true;
            }
            this.bindL2CodesForPrepopulating(
              response.data[0][0].ServiceLineL1,
              0
            );
            this.setState({ splitData: nextSocialData });
          }
          let isfrom = new URLSearchParams(window.location.search).get(
            "isFrom"
          );
          setTimeout(() => {
            this.setState({ transId: EntityId });
            this.setState({
              totalAmount: parseFloat(response.data[0][0].PerformanceAmount),
            });
            this.setState({ isFrom: isfrom });
            this.setState({ splitData: nextSocialData });
          }, 500);
          if (response.data[1].length > 0) {
            this.setState({ commentsArray: response.data[1] });
          }
          this.bindTransactionData(EntityId, lineNumber);
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
  //this method will get data for Po Transaction meta data on right hand side
  bindTransactionData = (transId, linenumber) => {
    var obj = {
      entityId: transId,
      linenumber: linenumber,
      UserName: this.getName(),
    };
    axios
      .post(config.baseURL + "getPODetails", obj)
      .then((response) => {
        console.log(response);
        this.setState({ transctionData: response.data[0] });

        this.setState({ vendorName: this.state.transctionData[2].Values })
        this.setState({ vendorCode: this.state.transctionData[1].Values })
        // this.setState({ vendorValue: this.state.vendorData.find(obj => obj.value === this.state.vendorName) })
        // console.log(this.state.vendorName,"vendorValuevendorValuevendorValue")
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };
  //this method will get software from db by calling an api and bind it to required session attribute
  bindSoftwareNames = (id, lineNumber,postingDate) => {
    axios
      .get(config.baseURL + "getServiceSoftwareNames")
      .then((response) => {
        var swarray = response.data;
        var otherobj = {
          label: "Other",
          value: "Other",
        };
        swarray.push(otherobj);

        this.setState({ softwareNames: swarray });
        this.bindSplitAssignDetails(id, lineNumber,postingDate);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };
  //this method will get Service data and bind to required sesssion Attributes
  bindL1Codes = () => {
    axios
      .get(config.baseURL + "getL1Codes")
      .then((response) => {
        this.setState({ L1Codes: response.data });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };
  //this method will get Service line data and bind to required sesssion Attributes
  bindL2Codes = (l1Code, idx) => {
    axios
      .get(config.baseURL + "getL2Codes/" + l1Code)
      .then((response) => {
        let arrayData = this.state.splitData.slice();
        arrayData[idx].l2code = "";
        arrayData[idx].L2CodeList = response.data;
        arrayData[idx].isddlLoading = false;
        arrayData[idx].pdAllocationCode = "";
        arrayData[idx].pdAllocationCodeDesc = "";
        this.setState({ splitData: arrayData });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //this method will get Service line for prepopulating  data and bind to required sesssion Attributes
  bindL2CodesForPrepopulating = (l1Code, idx) => {
    axios
      .get(config.baseURL + "getL2Codes/" + l1Code)
      .then((response) => {
        let arrayData = this.state.splitData.slice();
        arrayData[idx].L2CodeList = response.data;
        this.setState({ splitData: arrayData });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //this method will get Allocation code based on mgccode when we select service and service line
  getPdAllocationCode = (index, MGCCode) => {
    axios
      .get(config.baseURL + "getPDAllocationCode/" + MGCCode)
      .then((response) => {
        let nextSocialData = this.state.splitData.slice();
        if (response.data.length > 0) {
          nextSocialData[index].pdAllocationCode =
            response.data[0].AllocationCode;
          nextSocialData[index].pdAllocationCodeDesc =
            response.data[0].AllocationDesc;
          if (response.data[0].AllocationCode.trimEnd() === "U4") {
            var softwareArray = this.state.softwareNames;
            nextSocialData[index].softwarelist = softwareArray;
            nextSocialData[index].softwareDisplay = true;
          } else {
            nextSocialData[index].software = "";
            nextSocialData[index].softwareDisplay = false;
          }
          //nextSocialData[index].l2mappingId = response.data[0].MgcDetailsId;
        } else {
          nextSocialData[index].pdAllocationCode = "";
          nextSocialData[index].pdAllocationCodeDesc = "";
          nextSocialData[index].software = "";
          nextSocialData[index].softwareDisplay = false;
        }

        this.setState({ splitData: nextSocialData });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // getting vendor Name 
  VendorDetails = () => {  
    this.setState({ isLoading: true });
    this.setState({isvndlLoading:true})  
    axios
      .get(config.baseURL + "getVendorDetails")
      .then((response) => {
        console.log(response, "resss");
        setTimeout(() => { this.setState({ vendorData: response.data }) }, 50);
        if(this.state.vendorData){
          this.setState({isvndlLoading:false})
         }
          console.log(this.state.vendorName, "vendor")
          // this.setState({  vendorValue: this.state.vendorData.find(obj => obj.value === this.state.vendorName)})
          this.setState({ isLoading: false });
        
        console.log(response.data, "response.data")
// for (let i = 0; i < 10000; i = i + 1) {
//   this.state.vendorData.push({ value: i, label: `Option ${i}` });
// }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
        this.setState({isvndlLoading:false})
      });
  };
  // get vendor code
  getVendorCode = (v) => {
    try {
      if (!v || !v.value){
        this.setState({ vendorCode: '' });
        this.setState({ vendorValue: [] });
      }
      else {
        this.setState({ vendorValue: v.value })
        axios
          .get(config.baseURL + "getVendorCode/" + v.value)
          .then((response) => {
            this.setState({ vendorCode: response.data[0].vendor });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  //this method will add new row to attribute list
  handleAdd = (index) => {
    try {
      let array = this.state.splitData;
      array.push({
        id: array.length + 1,
        amount: "",
        l1Code: "",
        l2Code: "",
        pdAllocationCode: "",
        pdAllocationCodeDesc: "",
        L2CodeList: [],
        showAddButton: true,
        disablebtn: false,
        opacity: "1",
        l2mappingId: "",
        l2Id: "",
        software: "",
        softwareDisplay: false,
        softwarelist: [],
      });
      this.setState({ splitData: array });
      let sparray = this.state.splitData.slice();
      sparray[index].showAddButton = false;
      this.setState({ splitData: sparray });
    } catch (error) {
      console.log(error);
    }
  };

  onKeyDown = (event) => {
    var ASCIICode = event.which ? event.which : event.keyCode;
    //ASCIICode === 189 ||
    if (
      ASCIICode === 187 ||
      ASCIICode === 101 ||
      ASCIICode === 69 ||
      ASCIICode === 40 ||
      ASCIICode === 38
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  //this method will handle inputChange event called when we enter data and updte the required session attribute
  handleInputValueChange = (e, idx) => {
    try {
      var t = e.target.value;
      e.target.value =
        t.indexOf(".") >= 0
          ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
          : t;
      e.target.setAttribute("class", "amountInput");
      let nextSocialData = this.state.splitData.slice();
      nextSocialData[idx].amount = e.target.value;
      nextSocialData[idx].isAmountHighlight = false;
      this.setState({ splitData: nextSocialData });
      var i;
      let array = this.state.splitData;
      var runningAmount = 0;
      for (i = 0; i < array.length; i++) {
        var rowAmount = array[i].amount !== "" ? array[i].amount : 0;
        let amount = parseFloat(rowAmount);
        runningAmount += amount;
      }
      let amnt = 0;
      runningAmount = runningAmount.toFixed(2);
      amnt = parseFloat(this.state.totalAmount) - runningAmount;

      let sparray = this.state.splitData.slice();

      if (parseFloat(runningAmount) === this.state.totalAmount) {
        sparray[sparray.length - 1].disablebtn = true;
        sparray[sparray.length - 1].opacity = ".5";
        this.state.isSaveDisabled = false;
        this.setState({ savebtnOpacity: "1" });
      } else {
        //{
        sparray[sparray.length - 1].disablebtn = false;
        sparray[sparray.length - 1].opacity = "1";
        //}
        this.state.isSaveDisabled = true;
        this.setState({ savebtnOpacity: ".5" });
      }

      this.setState({ splitData: sparray });
      this.setState({ remainingAmount: parseFloat(amnt).toFixed(2) });
    } catch (error) {
      console.log(error);
    }
  };
  //called when you move focus from input controls
  onpaste = (e, idx) => {
    try {
      if (e.target.value == 0) {
        let nextSocialData = this.state.splitData.slice();
        nextSocialData[idx].amount = "";
        nextSocialData[idx].isAmountHighlight = false;
        this.setState({ splitData: nextSocialData });
      } else {
        var i;
        let array = this.state.splitData;
        var runningAmount = 0;
        for (i = 0; i < array.length; i++) {
          var rowAmount = array[i].amount !== "" ? array[i].amount : 0;
          let amount = parseFloat(rowAmount);
          runningAmount += amount;
        }
        let amnt = 0;
        runningAmount = runningAmount.toFixed(2);
        amnt = parseFloat(this.state.totalAmount) - runningAmount;

        let sparray = this.state.splitData.slice();
        // if (amnt !=this.state.totalAmount) {
        //   //nextSocialData[idx].isAmountHighlight=true;
        //   //this.setState({ splitData: nextSocialData });
        //   toast.error(messages.mismatchAmount, {
        //     autoClose: 10000,
        //   });
        // }
        // runningAmount =
        //   this.state.totalAmount > 0 ? runningAmount : -Math.abs(runningAmount);
        if (parseFloat(runningAmount) === this.state.totalAmount) {
          //sparray[idx].showAddButton = false;
          sparray[sparray.length - 1].disablebtn = true;
          sparray[sparray.length - 1].opacity = ".5";
          this.state.isSaveDisabled = false;
          this.setState({ savebtnOpacity: "1" });
        } else {
          // if(idx==sparray.length-1)
          //{
          // sparray[idx].showAddButton = true;
          sparray[sparray.length - 1].disablebtn = false;
          sparray[sparray.length - 1].opacity = "1";
          //}
          this.state.isSaveDisabled = true;
          this.setState({ savebtnOpacity: ".5" });
        }

        this.setState({ splitData: sparray });
        this.setState({ remainingAmount: parseFloat(amnt).toFixed(2) });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //this method will handle inputCommentChange event called when we enter data in to comments and updte the required session attribute
  handleCommentsChange = (e) => {
    try {
      this.setState({ comments: e.target.value });
    } catch (error) {
      console.log(error);
    }
  };
  //in this method ae are setting value for service Drop down based on session attribute
  setl1_values = (idx) => {
    try {
      let nextSocialData = this.state.splitData.slice();
      return this.state.L1Codes.filter(
        (e) => e.label === nextSocialData[idx].l1Code
      );
    } catch (error) {
      console.log(error);
    }
  };
  //in this method ae are setting value for service line Drop down based on session attribute
  setl2_values = (idx) => {
    try {
      let nextSocialData = this.state.splitData.slice();
      return nextSocialData[idx].L2CodeList.filter(
        (e) => e.label === nextSocialData[idx].l2Code
      );
    } catch (error) {
      console.log(error);
    }
  };
  //in this method ae are setting value for softeware Drop down based on session attribute
  setSoftware_values = (idx) => {
    try {
      let nextSocialData = this.state.splitData.slice();
      return nextSocialData[idx].softwarelist.filter(
        (e) => e.label === nextSocialData[idx].software
      );
    } catch (error) {
      console.log(error);
    }
  };
  //this method will handle Service Change event called when we select data and updte the required session attribute
  handleL1codeChange = (e, l1label, idx, l1value) => {
    try {
      document.getElementById(e).setAttribute("class", "");
      let nextSocialData = this.state.splitData.slice();
      nextSocialData[idx].l1Code = l1label;
      nextSocialData[idx].l2Code = "";
      nextSocialData[idx].L2CodeList = [];
      nextSocialData[idx].isddlLoading = true;
      nextSocialData[idx].pdAllocationCode = "";
      nextSocialData[idx].pdAllocationCodeDesc = "";
      nextSocialData[idx].software = "";
      nextSocialData[idx].softwareDisplay = false;
      nextSocialData[idx].l2mappingId = ""
      setTimeout(() => {
        this.setState({ splitData: nextSocialData });
      }, 500);

      this.bindL2Codes(l1label, idx);
    } catch (error) {
      console.log(error);
    }
  };
  //this method will handle software Change event called when we select data and updte the required session attribute
  handleSoftwarecodeChange = (SoftwareName, idx) => {
    try {
      let nextSocialData = this.state.splitData.slice();
      nextSocialData[idx].software = SoftwareName;
      setTimeout(() => {
        this.setState({ splitData: nextSocialData });
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };
  //this method will handle Service line Change event called when we select data and updte the required session attribute
  handleL2codeChange = (e, l2value, idx, MGCCode) => {
    try {
      document.getElementById(e).setAttribute("class", "");
      let nextSocialData = this.state.splitData.slice();

      nextSocialData[idx].l2Code = l2value;
      nextSocialData[idx].l2mappingId = MGCCode;

      setTimeout(() => {
        this.setState({ splitData: nextSocialData });
      }, 500);
      this.getPdAllocationCode(idx, MGCCode);
    } catch (error) {
      console.log(error);
    }
  };

  //this method will remove row from attribute list
  handleRemove = (idx) => {
    try {
      let someArray = this.state.splitData;
      var runningAmount = 0;
      if (someArray.length > 1) {
        someArray.splice(idx, 1);
        this.setState({ splitData: someArray });
        var i;
        let array = this.state.splitData;

        for (i = 0; i < array.length; i++) {
          var rowAmount = array[i].amount !== "" ? array[i].amount : 0;
          let amount = parseFloat(rowAmount);
          runningAmount += amount;
        }

        let amnt = 0;
        amnt = parseFloat(this.state.totalAmount) - runningAmount;
        this.setState({ remainingAmount: parseFloat(amnt).toFixed(2) });
        let sparray = this.state.splitData.slice();
        sparray[sparray.length - 1].showAddButton = true;
        if (amnt === 0) {
          sparray[sparray.length - 1].disablebtn = true;
          sparray[sparray.length - 1].opacity = ".5";
          this.state.isSaveDisabled = false;
          this.setState({ savebtnOpacity: "1" });
        } else {
          sparray[sparray.length - 1].disablebtn = false;
          sparray[sparray.length - 1].opacity = "1";
          this.state.isSaveDisabled = true;
          this.setState({ savebtnOpacity: ".5" });
        }
        this.setState({ splitData: sparray });
      }
    } catch (error) { }
  };

  //this method will call api to save data from UI to DB
  submitData = () => {
    try {
      if (this.validateForm()) {
        const insertObject = {};
        insertObject.transId = this.state.transId;
        insertObject.comments = this.state.comments.trimEnd().trimStart();
        insertObject.totalAmount = this.state.totalAmount;
        insertObject.issplitted =
          this.state.splitObjectArray.length > 1 ? 1 : 0;
        insertObject.splitData = this.state.splitObjectArray;
        insertObject.createdBy = this.getName();
        insertObject.screenName = "SplitorAssign";
        insertObject.vendorcode = this.state.vendorCode;

        this.setState({ isLoading: true });
        axios
          .post(config.baseURL + "saveTransactions", insertObject)
          .then((response) => {
            this.setState({ isLoading: false });
            let result = response && response.data && response.data.recordsets[0] && response.data.recordsets[0][0]? response.data.recordsets[0][0]: null;
            if(result && result.State == 1) {
              // handle success
              window.history.pushState(
                "page2",
                "Title",
                this.state.isFrom !== "search" ? "/UnAssignedPOList" : "/SearchPo"
              );
              document.getElementById("submittedPopup").style.display = "block";
              document.getElementById("div-overlay").style.display = "block";
              let data = JSON.parse(sessionStorage.getItem("filtredrow"))
              // const filteredArray1 = data.filter((res) => res.EntityId !== this.state.transctionData.Values);
              // console.log(filteredArray1,"filteredArray1")
              if(data!== null && data!== undefined) {
                const filteredArray = data.filter((res) => res.PurchasingDocument !== this.state.transctionData[0].Values);
                sessionStorage.setItem("filtredrow", JSON.stringify(filteredArray));
              }    
            }
            else {
              this.setState({errorMessage: result.ErrorMessage ? result.ErrorMessage : "The Submit failed."});
              this.setState({errorMessageContent: result.ErrorMessageContent ? result.ErrorMessageContent : "The Submit failed."});
              this.setState({errorLogNumber: result.ErrorLogNumber ? result.ErrorLogNumber : ""});
              this.setState({additionalErrorContent: result.AdditionalErrorContent ? result.AdditionalErrorContent : ""});
              this.setState({showErrorPopup: true});
            }
          })
          .catch((error) => {
            // handle error
            console.log(error)
            this.setState({ isLoading: false });
            this.setState({ splitObjectArray: [] });
            toast.error("The Submit failed.", {
              autoClose: 10000,
            });
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //this method handles functionlity when you close the submit popup
  closeSubmittedPopup() {
    try {
      document.getElementById("submittedPopup").style.display = "none";
      document.getElementById("div-overlay").style.display = "none";
      if (this.state.isFrom !== "search") {
        window.location.href = "/UnAssignedPOList";
      } else {
        window.location.href = "/SearchPo";
      }
    } catch (error) {
      console.log(error);
    }
  }
  //this method validate the form based on business functionalities
  validateForm = () => {
    try {
      var i;
      this.setState({ splitObjectArray: [] });
      let array = this.state.splitData;
      var totalPOAmount = 0;
      var result = true;
      var count = 0;
      for (i = 0; i < array.length; i++) {
        var amnt = array[i].amount;
        var l2code = array[i].l2Code;
        var l1code = array[i].l1Code;
        var software = array[i].software;
        var softwareDisplay = array[i].softwareDisplay;

        if (amnt === "" || amnt === undefined) {
          // var namectrl = document.getElementById("txt" + i);
          // namectrl.setAttribute("class", "amountInput errorClass");
          result = false;
        } else {
          if (
            this.validateNegitiveValue(
              parseFloat(array[i].amount),
              this.state.totalAmount
            )
          ) {
            var runningamount = parseFloat(array[i].amount);
            totalPOAmount += runningamount;
          } else {
            return false;
          }
        }
        if (l1code === "" || l1code === undefined) {
          // var socialctrl = document.getElementById("ddl1" + i);
          // socialctrl.setAttribute("class", "errorClass");
          result = false;
        }
        if (l2code === "" || l2code === undefined) {
          // var socialctrl = document.getElementById("ddl2" + i);
          // socialctrl.setAttribute("class", "errorClass");
          result = false;
        }
        if (software === "" && softwareDisplay) {
          toast.error(messages.softwaremandatory, {
            autoClose: 10000,
          });
          return false;
        }

        count = count + this.validateDuplicateServiceServiceLine(i);
        // if(!this.validateDuplicateServiceServiceLine(i))
        // {
        //   toast.error(messages.DuplicateL1L2, {
        //     autoClose: 10000,
        //   });
        // }

        var obj = {
          MgcCode: array[i].l2mappingId,
          ServiceL2: array[i].l2Code,
          LineId: array[i].id,
          Amount: parseFloat(array[i].amount),
          SoftwareName: software,
        };
        this.state.splitObjectArray.push(obj);
      }

      if (result) {
        totalPOAmount = totalPOAmount.toFixed(2);
        if (parseFloat(totalPOAmount) !== parseFloat(this.state.totalAmount)) {
          toast.error(messages.mismatchAmount, {
            autoClose: 10000,
          });
          result = false;
        } else if (count > 0) {
          toast.error(messages.DuplicateL1L2, {
            autoClose: 10000,
          });
          result = false;
        } else {
          if (this.state.comments.trimEnd().trimStart() === "") {
            result = false;
            toast.error(messages.validationMesage, {
              autoClose: 10000,
            });
          }
        }
      } else {
        toast.error(messages.validationMesage, {
          autoClose: 10000,
        });
      }

      return result;
    } catch (error) {
      console.log(error);
    }
  };
  //halde cancel click in the page
  cancelClick = () => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="conformDiv">
              <p className="conformBody">
                If you cancel then your changes will be lost. Are you sure you
                want to cancel?
              </p>
              <div className="ClsSearchPOsButton">
                <button
                  onClick={() => {
                    if (this.state.isFrom !== "search") {
                      window.location.href = "/UnAssignedPOList";
                    } else {
                      window.location.href = "/SearchPo";
                    }
                  }}
                  className="Clsbutton ClsSearch"
                >
                  Yes
                </button>
                <button onClick={onClose} className="Clsbutton ClsReset">
                  No
                </button>
              </div>
            </div>
          );
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  //retun html for input control based n condition
  getControl = (isdisplay, index) => {
    if (isdisplay) {
      let nextSocialData = this.state.splitData.slice();
      return (
        <Select
          id={"ddl3" + index}
          options={nextSocialData[index].softwarelist}
          value={this.setSoftware_values(index)}
          onChange={(e) => {
            this.handleSoftwarecodeChange(e !== null ? e.label : "", index);
          }}
        />
      );
    } else {
      return (
        <input
          className="amountInput form-control"
          id={"txtDummy" + index}
          type="text"
          disabled
        />
      );
    }
  };
  //validate Duplicate service and service line for different splits
  validateDuplicateServiceServiceLine = (idx) => {
    try {
      let splitArray = this.state.splitData.slice();
      let serviceLine = splitArray[idx].l1Code;
      let service = splitArray[idx].l2Code;
      if (
        serviceLine !== null &&
        serviceLine !== "" &&
        serviceLine !== undefined
      ) {
        let index = splitArray.findIndex(
          (row) => row.l1Code === serviceLine && row.l2Code === service
        );
        if (index >= 0 && index !== idx) {
          return 1;
        } else {
          return 0;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //validate input amounts are negitive or not for  for different splits
  validateNegitiveValue = (amount, totalAmount) => {
    var result = true;
    try {
      if (totalAmount < 0 && amount > 0) {
        result = false;
        toast.error(messages.NegativeNumberError, {
          autoClose: 10000,
        });
        return result;
      }
      if (totalAmount > 0 && amount < 0) {
        result = false;
        toast.error(messages.PositiveNumberError, {
          autoClose: 10000,
        });
        return result;
      } else {
        return result;
      }
    } catch (error) {
      console.log(error);
    }
  };
 
  handleOnSearch = (string, results) => {
    this.setState({ vendorName: string })
    this.getVendorCode(string)
  };

  handleOnHover = (result) => {
    console.log(result,"ress");
  };

  handleOnSelect = (item) => {
    console.log(item.label, "itemm");
    this.getVendorCode(item.label)
    this.setState({ vendorValue: item.label })
    //this.getVendorCode(string)
  };

  handleOnFocus = () => {
    console.log("Focused");
  };
  filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.label,
    limit: 100,
  });

  render() {
    return (
      <div>
        <LoadingOverlay active={this.state.isLoading} spinner>
          {this.state.isLoading === true ? (
            ""
          ) : (
            <div class="body-support-block">
              <div id="Unassigned" class="tabcontent tabcontent1">
                <div class="container-fluid splitAssignContainerFluid">
                  <div class="row">
                    <div class="cardsContainer bodyContentHeight">
                      <div class="responsive-table">
                        <div class="ClsSplitLeft">
                          <div class="ClsSplitTitle">Split/Assign PO</div>
                          <div class="ClsSplitAssigndiv">
                            <div class="ClsSplitAddDiv">
                              <table className="table TableTitleHeight">
                                <thead className="splittablehead">
                                  <tr>
                                    <td class="col-2">
                                      <label for="lname">
                                        Performance $'s{" "}
                                        <span class="required"> *</span>
                                      </label>
                                    </td>
                                    <td className="col-2 ClsSplitass3">
                                      {" "}
                                      <label for="lname">
                                        Service Line{" "}
                                        <span class="required"> *</span>
                                      </label>
                                    </td>
                                    <td className="col-2 ClsSplitass3">
                                      {" "}
                                      <label for="lname">
                                        Service <span class="required"> *</span>
                                      </label>
                                    </td>
                                    <td className="col-2">
                                      {" "}
                                      <label for="lname">Allocation Code</label>
                                    </td>
                                    <td className="col-2">
                                      {" "}
                                      <label for="lname">Software Name</label>
                                    </td>
                                    <td className="col-2">
                                      {" "}
                                      <label for="lname">Parent MGC Code</label>
                                    </td>
                                    <td className=" col-2 ClsSplitass2">
                                      <label for="lname"> &nbsp;</label>
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.splitData.map((Social, idx) => (
                                    <tr key={idx} className="col-12" row={idx}>
                                      <td class="col-2 ">
                                        <div>
                                          {" "}
                                          <input
                                            className={
                                              Social.isAmountHighlight
                                                ? "amountInput errorClass form-control"
                                                : "amountInput form-control"
                                            }
                                            id={"txt" + idx}
                                            value={Social.amount}
                                            type="number"
                                            autocomplete="off"
                                            placeholder={`Enter Amount`}
                                            onKeyDown={(e) => {
                                              this.onKeyDown(e, idx);
                                            }}
                                            onChange={(e) => {
                                              this.handleInputValueChange(
                                                e,
                                                idx
                                              );
                                            }}
                                            onBlur={(e) => {
                                              this.onpaste(e, idx);
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td className="col-2 ClsSplitass3">
                                        <div>
                                          <Select
                                            id={"ddl1" + idx}
                                            options={this.state.L1Codes}
                                            value={this.setl1_values(idx)}
                                            onChange={(e) => {
                                              this.handleL1codeChange(
                                                "ddl1" + idx,
                                                e.label,
                                                idx,
                                                e.value
                                              );
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td className="col-2 ClsSplitass3">
                                        <div>
                                          <Select
                                            id={"ddl2" + idx}
                                            options={
                                              this.state.splitData[idx]
                                                .L2CodeList
                                            }
                                            value={this.setl2_values(idx)}
                                            isLoading={
                                              this.state.splitData[idx]
                                                .isddlLoading
                                            }
                                            onChange={(e) => {
                                              this.handleL2codeChange(
                                                "ddl2" + idx,
                                                e !== null ? e.label : "",
                                                idx,
                                                e !== null ? e.value : ""
                                              );
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td id={"pdtd" + idx} className="col-2 ">
                                        <div data-for="AllocDescSplit" data-tip={Social.pdAllocationCodeDesc} >
                                          <input
                                            className="amountInput form-control"
                                            id={"txtPD" + idx}
                                            type="text"
                                            value={Social.pdAllocationCode}
                                            disabled
                                          />

                                          <ReactTooltip
                                            aria-haspopup="true"
                                            role="example"
                                            className="extraClass"
                                            id="AllocDescSplit"
                                            multiline="true"
                                            type="dark"
                                          ></ReactTooltip>
                                        </div>
                                      </td>
                                      <td className="col-2 ClsSplitass3">
                                        <div>
                                          {this.getControl(
                                            Social.softwareDisplay,
                                            idx
                                          )}
                                        </div>
                                      </td>
                                      <td class="col-2 ClsSplitass3">
                                        <div  >
                                          <input
                                            className="amountInput form-control"
                                            id={"txtPD1" + idx}
                                            type="text"
                                            value={Social.l2mappingId}
                                            disabled
                                          />

                                        </div>
                                      </td>
                                      <td className="col-1 ClsSplitass3">
                                        <div>
                                          <button
                                            tabindex="0"
                                            onClick={() =>
                                              this.handleRemove(idx)
                                            }
                                            className="removeSpan Clsbutton"
                                          >
                                            <img
                                              style={{ width: "24px" }}
                                              src={RemoveIcon}
                                              alt="remove"
                                            />
                                          </button>
                                          <button
                                            style={{ opacity: Social.opacity }}
                                            className={
                                              Social.showAddButton
                                                ? "Clsbutton ClsAdd"
                                                : "Clsbutton ClsAdd hidden"
                                            }
                                            onClick={() => this.handleAdd(idx)}
                                            disabled={Social.disablebtn}
                                          >
                                            <img
                                              className="addbuttonImage"
                                              src={AddIcon}
                                              alt="add"
                                            />
                                          </button>
                                        </div>
                                      </td>
                                      {/* <td class="col-2">
                                       <div data-for="AllocDescSplit" data-tip={Social.pdAllocationCodeDesc} > 
                                         <input
                                           className="amountInput form-control"
                                           id={"txtPD" + idx}
                                           type="text"
                                           value={Social.l2mappingId}
                                           disabled  
                                         />
                                        
                                         <ReactTooltip
                                           aria-haspopup="true"
                                           role="example"
                                           className="extraClass"
                                           id="AllocDescSplit"
                                           multiline="true"
                                           type="dark"
                                         ></ReactTooltip>
                                       </div>
                                     </td> */}

                                    </tr>

                                  ))
                                  }

                                </tbody>
                              </table>
                            </div>
                            <div class="ClsTotalAmount">
                              <div class="row">
                                <div class="col-4">
                                  {" "}
                                  <label for="lname" className="labelAmounts">
                                    Total Amount (USD$)
                                  </label>{" "}
                                  <span>
                                    <NumberFormat
                                      value={this.state.totalAmount}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                  </span>{" "}
                                </div>
                                <div class="col-6">
                                  {" "}
                                  <label
                                    for="lname"
                                    className="labelAmounts"
                                    style={{ marginLeft: "35px" }}
                                  >
                                    Remaining Amount (USD$)
                                  </label>{" "}
                                  <span>
                                    <NumberFormat
                                      value={this.state.remainingAmount}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                  </span>{" "}
                                </div>
                              </div>
                            </div>
                            <div>

                              <div className="row m-2 ">
                                <div className="ClsSplitass3 splitselect-v">
                                  <label className="form-label-split ">
                                    Vendor Name
                                  </label>
                                  <div className="reactautofill">
                                  <Autocomplete
                                   className=" reactautofill autofilltextarea"
                                    freeSolo
                                    id="free-solo-demo"
                                    defaultValue={this.state.vendorName}
                                   value={this.state.vendorName}
                                   autoHighlight
                                   filterOptions={this.filterOptions}
                                   loading={this.state.isvndlLoading}
                                   openOnFocus
                                   filterSelectedOptions
                                    //value={this.state.vendorData.filter((e) => e.label === this.state.vendorName) || {}}
                                    options={this.state.vendorData}
                                    onChange={(e,v) =>this.getVendorCode(v)}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label=""
                                        variant="outlined"
                                        placeholder="Select..."
                                        size="small"
                                       
                                      />
                                    )}
                                  
                                  />
                                  
                                  </div>
                                  {/* <Select
                                    value={this.state.vendorValue}
                                    //filterOption={({label}, query) => label.indexOf(query) >= 0 && (this.state.idrop)++ < this.state.resultLimit}
                                    options={
                                      this.state.vendorData
                                    }
                                    //  isLoading={
                                    //           this.state.splitData[idx]
                                    //             .isddlLoading
                                    //         }
                                    // placeholder={this.state.vendorCode?"":"Select..."}
                                    onChange={(e) => {
                                      this.getVendorCode(e)
                                    }}
                                   // maxMenuHeight={175}
                                  /> */}
                                </div>
                                <div
                                  className=" "
                                >
                                  <label className="form-label-split">
                                    Vendor Code
                                  </label>
                                  <input
                                    className="amountInput form-control "
                                    //id={"txtPD" + idx}
                                    type="text"
                                    value={this.state.vendorCode}
                                    disabled
                                  />
                                </div>

                              </div>






                            </div>
                            <div class="ClsSplitCommentsArea">
                              <div>
                                {" "}
                                <label for="lname">
                                  Comments<span class="required"> *</span>
                                </label>{" "}
                                <textarea
                                  rows="4"
                                  cols="50"
                                  maxLength="3000"
                                  name="comment"
                                  form="usrform"
                                  class="form-control"
                                  onChange={(e) => {
                                    this.handleCommentsChange(e);
                                  }}
                                >
                                  {" "}
                                </textarea>{" "}
                              </div>
                            </div>
                            {this.state.commentsArray.map((cmntsObj, idx) => (
                              <div class="commentsHistory">
                                <div class="commentSingle">
                                  <div class="row">
                                    <div class="col-md-8">
                                      <h4>{cmntsObj.CreatedBy}</h4>
                                      <p class="textBreak">
                                        {cmntsObj.Comment}
                                      </p>
                                    </div>
                                    <div class="col-md-4">
                                      <p class="commentDate">
                                        {cmntsObj.CreatedDate}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div class="ClsSearchPOsButton">
                            <button
                              style={{ opacity: this.state.savebtnOpacity }}
                              class="Clsbutton ClsSearch"
                              onClick={() => this.submitData()}
                              disabled={this.state.isSaveDisabled}
                            >
                              Save
                            </button>
                            <button
                              class="Clsbutton ClsReset"
                              onClick={() => this.cancelClick()}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                        <div class="ClsSplitRight">
                          <div class="ClsSplitTitle">PO Details</div>
                          <div class="ClsSplitDiv">
                            <ul>
                              {this.state.transctionData.map((value, index) => {
                                return (
                                  <li>
                                    <span>{value.Columns}</span>
                                    <span>{value.Values}</span>{" "}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </LoadingOverlay>

        <div className="modal show" id="submittedPopup">
          <div class="modal-dialog modal-success" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="successMessageMain">
                  <img src={popupIcon} alt="popup" />
                  <h4>Successfully Updated!</h4>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => this.closeSubmittedPopup()}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showErrorPopup ?
        (
          <div className="modalhow" id="errorPopup">
            <Confirmation 
              showConfirmation={this.state.showErrorPopup}
              ModalContent={this.state.errorMessageContent}
              handleClose={() => {
                this.setState({showErrorPopup:false})
              }}
              handleConfirm={() => {
                let copyMessage = `${this.state.errorMessage} | Log Number:${this.state.errorLogNumber} | Log Error:${this.state.additionalErrorContent}`
                navigator.clipboard.writeText(copyMessage);
              }}
              primaryButtonText = "Copy"
              secondaryButtonText="Close"
              additionalContent = {this.state.additionalErrorContent}
            />  
          </div>
        ):""}
      </div>
    );
  }
}